import swatches from '../../../../util/swatches.json';

export default {
  chart: [
    swatches['light-blue-vivid-600'],
    swatches['blue-grey-300'],
    swatches['blue-grey-200'],
    swatches['blue-grey-100']
  ]
};
