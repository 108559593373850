class Fields {
  exerciseGroups = {
    'leg-strength': [
      '20m-sprint-time',
      'rebound-box-jumps-time',
      'standing-broad-jump-dist',
      'rebound-box-jumps-timed-reps'
    ],
    'core-strength': [
      'tucked-leg-lifts-timed-reps',
      'piked-leg-lifts-max-reps',
      'dish-hold-max-time',
      'half-leg-lifts-max-reps',
      'piked-leg-lifts-timed-reps',
      'v-snaps-timed-reps'
    ],
    'upper-body-strength': [
      'rope-climb-points',
      'chin-ups-max-reps',
      'push-ups-max-reps',
      'cast-to-horizontal-max-reps',
      'rope-climb-time',
      'chin-ups-timed-reps'
    ],
    handstand: [
      'handstand-hold-wall-max-time',
      'handstand-hold-wall-shape-points',
      'press-to-handstand-floor-max-reps',
      'press-to-handstand-floor-shape-points',
      'cast-to-handstand-max-reps',
      'cast-to-handstand-shape-points',
      'handstand-hold-beam-max-time',
      'handstand-hold-beam-shape-points',
      'press-to-handstand-beam-max-reps',
      'press-to-handstand-beam-shape-points'
    ],
    flexibility: [
      'right-leg-splits-points',
      'left-leg-splits-points',
      'middle-leg-splits-points',
      'pike-position-points',
      'bridge-position-points',
      'back-bridge-recover-to-stand-points',
      'back-walkover-points',
      'front-walkover-points'
    ]
  };

  exerciseGroupNames = Object.getOwnPropertyNames(this.exerciseGroups);

  U9Fields = [
    '20m-sprint-time',
    'standing-broad-jump-dist',
    'rebound-box-jumps-timed-reps',
    'tucked-leg-lifts-timed-reps',
    'piked-leg-lifts-max-reps',
    'dish-hold-max-time',
    'rope-climb-points',
    'chin-ups-max-reps',
    'push-ups-max-reps',
    'cast-to-horizontal-max-reps',
    'handstand-hold-wall-max-time',
    'handstand-hold-wall-shape-points',
    'press-to-handstand-floor-max-reps',
    'press-to-handstand-floor-shape-points',
    'right-leg-splits-points',
    'left-leg-splits-points',
    'middle-leg-splits-points',
    'pike-position-points',
    'bridge-position-points',
    'back-bridge-recover-to-stand-points'
  ];

  U12Fields = [
    '20m-sprint-time',
    'rebound-box-jumps-time',
    'half-leg-lifts-max-reps',
    'piked-leg-lifts-timed-reps',
    'v-snaps-timed-reps',
    'rope-climb-time',
    'chin-ups-timed-reps',
    'cast-to-handstand-max-reps',
    'cast-to-handstand-shape-points',
    'handstand-hold-beam-max-time',
    'handstand-hold-beam-shape-points',
    'press-to-handstand-beam-max-reps',
    'press-to-handstand-beam-shape-points',
    'right-leg-splits-points',
    'left-leg-splits-points',
    'middle-leg-splits-points',
    'pike-position-points',
    'bridge-position-points',
    'back-walkover-points',
    'front-walkover-points'
  ];

  timeFields = [
    '20m-sprint-time',
    'dish-hold-max-time',
    'handstand-hold-wall-max-time',
    'rebound-box-jumps-time',
    'rope-climb-time',
    'handstand-hold-beam-max-time'
  ];

  distanceFields = ['height', 'standing-broad-jump-dist'];

  countFields = [
    'rebound-box-jumps-timed-reps',
    'tucked-leg-lifts-timed-reps',
    'piked-leg-lifts-max-reps',
    'piked-leg-lifts-timed-reps',
    'chin-ups-max-reps',
    'chin-ups-timed-reps',
    'push-ups-max-reps',
    'cast-to-horizontal-max-reps',
    'cast-to-handstand-max-reps',
    'press-to-handstand-floor-max-reps',
    'press-to-handstand-beam-max-reps',
    'half-leg-lifts-max-reps',
    'v-snaps-timed-reps'
  ];

  categoryFields = [
    'age-group',
    'rope-climb-points',
    'handstand-hold-wall-shape-points',
    'press-to-handstand-floor-shape-points',
    'cast-to-handstand-shape-points',
    'handstand-hold-beam-shape-points',
    'press-to-handstand-beam-shape-points',
    'right-leg-splits-points',
    'left-leg-splits-points',
    'middle-leg-splits-points',
    'pike-position-points',
    'bridge-position-points',
    'bridge-position-points',
    'back-bridge-recover-to-stand-points',
    'back-walkover-points',
    'front-walkover-points'
  ];

  fieldType(field) {
    if (this.timeFields.includes(field)) return 'time';
    if (this.distanceFields.includes(field)) return 'distance';
    if (this.countFields.includes(field)) return 'count';
    if (this.categoryFields.includes(field)) return 'category';
  }

  getAgeGroup(field) {
    if (this.U9Fields.includes(field) && !this.U12Fields.includes(field)) {
      return 'U9';
    }
    if (!this.U9Fields.includes(field) && this.U12Fields.includes(field)) {
      return '9-12yrs';
    }
    return null;
  }

  labels = new Map([
    ['age-group', 'Age Group'],
    ['height', 'Height'],
    ['20m-sprint-time', '20m Sprint'],
    ['standing-broad-jump-dist', 'Standing Broad Jump'],
    ['rebound-box-jumps-timed-reps', 'Rebound Box Jumps'],
    ['rebound-box-jumps-time', 'Rebound Box Jumps'],
    ['tucked-leg-lifts-timed-reps', 'Tucked Leg Lifts'],
    ['piked-leg-lifts-max-reps', 'Piked Leg Lifts'],
    ['piked-leg-lifts-timed-reps', 'Piked Leg Lifts'],
    ['dish-hold-max-time', 'Dish Hold'],
    ['half-leg-lifts-max-reps', 'Half Leg Lifts'],
    ['v-snaps-timed-reps', 'V-snaps'],
    ['rope-climb-points', 'Rope Climb'],
    ['rope-climb-time', 'Rope Climb'],
    ['chin-ups-max-reps', 'Chin Ups'],
    ['chin-ups-timed-reps', 'Chin Ups'],
    ['push-ups-max-reps', 'Push Ups'],
    ['cast-to-horizontal-max-reps', 'Cast to Horizontal'],
    ['handstand-hold-wall-max-time', 'Handstand Hold on wall'],
    ['handstand-hold-wall-shape-points', 'Handstand Hold on wall (shape)'],
    ['handstand-hold-beam-max-time', 'Handstand Hold on beam'],
    ['handstand-hold-beam-shape-points', 'Handstand Hold on beam (shape)'],
    ['press-to-handstand-floor-max-reps', 'Press to Handstand on floor'],
    [
      'press-to-handstand-floor-shape-points',
      'Press to Handstand on floor (shape)'
    ],
    ['press-to-handstand-beam-max-reps', 'Press to Handstand on beam'],
    ['press-to-handstand-beam-shape-points', 'Press to Handstand (shape)'],
    ['cast-to-handstand-max-reps', 'Cast to Handstand'],
    ['cast-to-handstand-shape-points', 'Cast to Handstand (shape)'],
    ['right-leg-splits-points', 'Right Leg Splits'],
    ['left-leg-splits-points', 'Left Leg Splits'],
    ['middle-leg-splits-points', 'Middle Leg Splits'],
    ['pike-position-points', 'Pike Position'],
    ['bridge-position-points', 'Bridge Position'],
    ['bridge-position-points', 'Bridge Position'],
    ['back-bridge-recover-to-stand-points', 'Back Bridge Recover To Stand'],
    ['back-walkover-points', 'Back Walkover'],
    ['front-walkover-points', 'Front Walkover'],

    ['leg-strength', 'Leg Strength'],
    ['core-strength', 'Core Strength'],
    ['upper-body-strength', 'Upper Body Strength'],
    ['handstand', 'Handstand'],
    ['flexibility', 'Flexibility']
  ]);

  label(field) {
    return this.labels.get(field);
  }

  condensedLabels = new Map([
    ['age-group', ['Age Group']],
    ['height', ['Height']],
    ['20m-sprint-time', ['20m Sprint']],
    ['standing-broad-jump-dist', ['Standing', 'Broad Jump']],
    ['rebound-box-jumps-timed-reps', ['Rebound', 'Box Jumps']],
    ['rebound-box-jumps-time', ['Rebound', 'Box Jumps']],
    ['tucked-leg-lifts-timed-reps', ['Tucked', 'Leg Lifts']],
    ['piked-leg-lifts-max-reps', ['Piked', 'Leg Lifts']],
    ['piked-leg-lifts-timed-reps', ['Piked', 'Leg Lifts']],
    ['dish-hold-max-time', 'Dish Hold'],
    ['half-leg-lifts-max-reps', 'Half Leg Lifts'],
    ['v-snaps-timed-reps', 'V-snaps'],
    ['rope-climb-points', ['Rope', 'Climb']],
    ['rope-climb-time', ['Rope', 'Climb']],
    ['chin-ups-max-reps', 'Chin Ups'],
    ['chin-ups-timed-reps', 'Chin Ups'],
    ['push-ups-max-reps', 'Push Ups'],
    ['cast-to-horizontal-max-reps', ['Cast to', 'Horizontal']],
    ['handstand-hold-wall-max-time', 'HS Hold'],
    ['handstand-hold-wall-shape-points', ['HS Hold', '(shape)']],
    ['handstand-hold-beam-max-time', 'HS Hold'],
    ['handstand-hold-beam-shape-points', ['HS Hold', '(shape)']],
    ['press-to-handstand-floor-max-reps', 'Press to HS'],
    ['press-to-handstand-floor-shape-points', ['Press to HS', '(shape)']],
    ['press-to-handstand-beam-max-reps', 'Press to HS'],
    ['press-to-handstand-beam-shape-points', ['Press to HS', '(shape)']],
    ['cast-to-handstand-max-reps', 'Cast to HS'],
    ['cast-to-handstand-shape-points', ['Cast to HS', '(shape)']],
    ['right-leg-splits-points', ['Right', 'Leg Splits']],
    ['left-leg-splits-points', ['Left', 'Leg Splits']],
    ['middle-leg-splits-points', ['Middle', 'Leg Splits']],
    ['pike-position-points', ['Pike', 'Position']],
    ['bridge-position-points', ['Bridge', 'Position']],
    ['bridge-position-points', 'Bridge Position'],
    [
      'back-bridge-recover-to-stand-points',
      ['Back Bridge', 'Recover To Stand']
    ],
    ['back-walkover-points', 'Back Walkover'],
    ['front-walkover-points', 'Front Walkover'],

    ['leg-strength', ['Leg Strength']],
    ['core-strength', ['Core', 'Strength']],
    ['upper-body-strength', ['Upper Body', 'Strength']],
    ['handstand', 'Handstand'],
    ['flexibility', 'Flexibility']
  ]);

  condensedLabel(field) {
    return this.condensedLabels.get(field);
  }

  unitLabels = new Map([
    ['age-group', null],
    ['height', 'cm'],
    ['20m-sprint-time', 'sec'],
    ['standing-broad-jump-dist', 'cm'],
    ['rebound-box-jumps-timed-reps', 'reps'],
    ['rebound-box-jumps-time', 'sec'],
    ['tucked-leg-lifts-timed-reps', 'reps'],
    ['piked-leg-lifts-max-reps', 'reps'],
    ['piked-leg-lifts-timed-reps', 'reps'],
    ['dish-hold-max-time', 'sec'],
    ['half-leg-lifts-max-reps', 'reps'],
    ['v-snaps-timed-reps', 'reps'],
    ['rope-climb-points', null],
    ['rope-climb-time', 'sec'],
    ['chin-ups-max-reps', 'reps'],
    ['chin-ups-timed-reps', 'reps'],
    ['push-ups-max-reps', 'reps'],
    ['cast-to-horizontal-max-reps', 'reps'],
    ['handstand-hold-wall-max-time', 'sec'],
    ['handstand-hold-wall-shape-points', null],
    ['handstand-hold-beam-max-time', 'sec'],
    ['handstand-hold-beam-shape-points', null],
    ['press-to-handstand-floor-max-reps', 'reps'],
    ['press-to-handstand-floor-shape-points', null],
    ['press-to-handstand-beam-max-reps', 'reps'],
    ['press-to-handstand-beam-shape-points', null],
    ['cast-to-handstand-max-reps', 'reps'],
    ['cast-to-handstand-shape-points', null],
    ['right-leg-splits-points', null],
    ['left-leg-splits-points', null],
    ['middle-leg-splits-points', null],
    ['pike-position-points', null],
    ['bridge-position-points', null],
    ['bridge-position-points', null],
    ['back-bridge-recover-to-stand-points', null],
    ['back-walkover-points', null],
    ['front-walkover-points', null]
  ]);

  unitLabel(field) {
    return this.unitLabels.get(field);
  }

  calculateAssessmentAreaScore(scores) {
    let completedScores = scores.filter(s => s !== null && s !== undefined);
    if (completedScores.length === 0) return null;

    const areaScore =
      completedScores.reduce((score, total) => total + score) /
      completedScores.length;
    return areaScore;
  }

  calculateScores(result) {
    const scores = {};

    // calculate individual exercise scores
    Object.getOwnPropertyNames(result).forEach(field => {
      const score = this.score(field, result);
      if (score !== null) scores[field] = score;
    });

    // calculate assessment area scores
    const ageGroup = result['age-group'];
    let legStrength,
      coreStrength,
      upperBodyStrength,
      handstand,
      flexibility = null;
    if (ageGroup === 'U9') {
      legStrength = this.calculateAssessmentAreaScore([
        scores['20m-sprint-time'],
        scores['standing-broad-jump-dist'],
        scores['rebound-box-jumps-timed-reps']
      ]);

      coreStrength = this.calculateAssessmentAreaScore([
        scores['tucked-leg-lifts-timed-reps'],
        scores['piked-leg-lifts-max-reps'],
        scores['dish-hold-max-time']
      ]);

      upperBodyStrength = this.calculateAssessmentAreaScore([
        scores['rope-climb-points'],
        scores['chin-ups-max-reps'],
        scores['push-ups-max-reps'],
        scores['cast-to-horizontal-max-reps']
      ]);

      handstand = this.calculateAssessmentAreaScore([
        scores['handstand-hold-wall-max-time'],
        scores['handstand-hold-wall-shape-points'],
        scores['press-to-handstand-floor-max-reps'],
        scores['press-to-handstand-floor-shape-points']
      ]);

      flexibility = this.calculateAssessmentAreaScore([
        scores['right-leg-splits-points'],
        scores['left-leg-splits-points'],
        scores['middle-leg-splits-points'],
        scores['pike-position-points'],
        scores['bridge-position-points'],
        scores['back-bridge-recover-to-stand-points']
      ]);

      if (legStrength !== null) scores['leg-strength'] = legStrength;
      if (coreStrength !== null) scores['core-strength'] = coreStrength;
      if (upperBodyStrength !== null)
        scores['upper-body-strength'] = upperBodyStrength;
      if (handstand !== null) scores['handstand'] = handstand;
      if (flexibility !== null) scores['flexibility'] = flexibility;

      let fieldsWithScore = this.U9Fields.filter(
        field => scores[field] !== null && scores[field] !== undefined
      );

      // if there is at least one score then calculate the total
      if (fieldsWithScore.length > 0) {
        scores['total'] = fieldsWithScore.reduce((total, field) => {
          return total + scores[field];
        }, 0);
        // calculate score as percentage of total possible (ignoring empty fields)
        scores['total-percent'] =
          scores['total'] / (fieldsWithScore.length * 10);
      }
      scores['numberCompleted'] = fieldsWithScore.length;
      scores['numberMissing'] = this.U9Fields.length - fieldsWithScore.length;
    } else if (ageGroup === '9-12yrs') {
      legStrength = this.calculateAssessmentAreaScore([
        scores['20m-sprint-time'],
        scores['rebound-box-jumps-time']
      ]);

      coreStrength = this.calculateAssessmentAreaScore([
        scores['half-leg-lifts-max-reps'],
        scores['piked-leg-lifts-timed-reps'],
        scores['v-snaps-timed-reps']
      ]);

      upperBodyStrength = this.calculateAssessmentAreaScore([
        scores['rope-climb-time'],
        scores['chin-ups-timed-reps']
      ]);

      handstand = this.calculateAssessmentAreaScore([
        scores['cast-to-handstand-max-reps'],
        scores['cast-to-handstand-shape-points'],
        scores['handstand-hold-beam-max-time'],
        scores['handstand-hold-beam-shape-points'],
        scores['press-to-handstand-beam-max-reps'],
        scores['press-to-handstand-beam-shape-points']
      ]);

      flexibility = this.calculateAssessmentAreaScore([
        scores['right-leg-splits-points'],
        scores['left-leg-splits-points'],
        scores['middle-leg-splits-points'],
        scores['pike-position-points'],
        scores['bridge-position-points'],
        scores['back-walkover-points'],
        scores['front-walkover-points']
      ]);

      if (legStrength !== null) scores['leg-strength'] = legStrength;
      if (coreStrength !== null) scores['core-strength'] = coreStrength;
      if (upperBodyStrength !== null)
        scores['upper-body-strength'] = upperBodyStrength;
      if (handstand !== null) scores['handstand'] = handstand;
      if (flexibility !== null) scores['flexibility'] = flexibility;

      let fieldsWithScore = this.U12Fields.filter(
        field => scores[field] !== null && scores[field] !== undefined
      );

      // if there is at least one score then calculate the total
      if (fieldsWithScore.length > 0) {
        scores['total'] = fieldsWithScore.reduce((total, field) => {
          return total + scores[field];
        }, 0);
        // calculate score as percentage of total possible (ignoring empty fields)
        scores['total-percent'] =
          scores['total'] / (fieldsWithScore.length * 10);
      }
      scores['numberCompleted'] = fieldsWithScore.length;
      scores['numberMissing'] = this.U12Fields.length - fieldsWithScore.length;
    }

    return scores;
  }

  score(field, result) {
    const ageGroup = result['age-group'];
    if (ageGroup === 'U9') {
      if (field === '20m-sprint-time') {
        let time = result['20m-sprint-time'];
        if (time !== null) {
          time = time / 1000;
          if (time < 3.5) return 10;
          else if (time < 3.6) return 9;
          else if (time < 3.7) return 8;
          else if (time < 3.8) return 7;
          else if (time < 3.9) return 6;
          else if (time < 4.0) return 5;
          else if (time < 4.1) return 4;
          else if (time < 4.2) return 3;
          else if (time < 4.3) return 2;
          else if (time < 4.4) return 1;
          else if (time < 4.5) return 0;
          else return 0;
        }
        return null;
      } else if (field === 'standing-broad-jump-dist') {
        const height = result['height'];
        const distance = result['standing-broad-jump-dist'];
        if (height !== null && distance !== null) {
          const diff = (distance - height) / 10;
          if (diff >= 80) return 10;
          else if (diff >= 75) return 9;
          else if (diff >= 70) return 8;
          else if (diff >= 65) return 7;
          else if (diff >= 60) return 6;
          else if (diff >= 55) return 5;
          else if (diff >= 50) return 4;
          else if (diff >= 45) return 3;
          else if (diff >= 40) return 2;
          else if (diff >= 35) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'rebound-box-jumps-timed-reps') {
        const reps = result['rebound-box-jumps-timed-reps'];
        //XXX these numbers would appear to be backwards in the protocol
        if (reps !== null) {
          if (reps >= 37) return 10;
          else if (reps >= 35) return 9;
          else if (reps >= 33) return 8;
          else if (reps >= 31) return 7;
          else if (reps >= 29) return 6;
          else if (reps >= 27) return 5;
          else if (reps >= 25) return 4;
          else if (reps >= 23) return 3;
          else if (reps >= 21) return 2;
          else if (reps >= 20) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'tucked-leg-lifts-timed-reps') {
        const reps = result['tucked-leg-lifts-timed-reps'];
        if (reps !== null) {
          if (reps >= 25) return 10;
          else if (reps >= 23) return 9;
          else if (reps >= 21) return 8;
          else if (reps >= 19) return 7;
          else if (reps >= 17) return 6;
          else if (reps >= 15) return 5;
          else if (reps >= 13) return 4;
          else if (reps >= 11) return 3;
          else if (reps >= 9) return 2;
          else if (reps >= 7) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'piked-leg-lifts-max-reps') {
        const reps = result['piked-leg-lifts-max-reps'];
        if (reps !== null) {
          if (reps >= 15) return 10;
          else if (reps >= 13) return 9;
          else if (reps >= 11) return 8;
          else if (reps >= 9) return 7;
          else if (reps >= 7) return 6;
          else if (reps >= 5) return 5;
          else if (reps >= 4) return 4;
          else if (reps >= 3) return 3;
          else if (reps >= 2) return 2;
          else if (reps >= 1) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'dish-hold-max-time') {
        let time = result['dish-hold-max-time'];
        if (time !== null) {
          time = time / 1000;
          if (time >= 120) return 10;
          else if (time >= 105) return 9;
          else if (time >= 90) return 8;
          else if (time >= 75) return 7;
          else if (time >= 60) return 6;
          else if (time >= 45) return 5;
          else if (time >= 30) return 4;
          else if (time >= 15) return 3;
          else if (time >= 5) return 2;
          else return 1;
        }
        return null;
      } else if (field === 'rope-climb-points') {
        const value = result['rope-climb-points'];
        if (value !== null) {
          if (value === 'full') return 10;
          else if (value === '3/4') return 8;
          else if (value === '1/2') return 5;
          else if (value === '1/4') return 3;
          else if (value === 'legs') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'chin-ups-max-reps') {
        const reps = result['chin-ups-max-reps'];
        if (reps !== null) {
          if (reps >= 15) return 10;
          else if (reps >= 13) return 9;
          else if (reps >= 11) return 8;
          else if (reps >= 9) return 7;
          else if (reps >= 7) return 6;
          else if (reps >= 5) return 5;
          else if (reps >= 4) return 4;
          else if (reps >= 3) return 3;
          else if (reps >= 2) return 2;
          else if (reps >= 1) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'push-ups-max-reps') {
        const reps = result['push-ups-max-reps'];
        if (reps !== null) {
          if (reps >= 25) return 10;
          else if (reps >= 23) return 9;
          else if (reps >= 21) return 8;
          else if (reps >= 19) return 7;
          else if (reps >= 17) return 6;
          else if (reps >= 15) return 5;
          else if (reps >= 13) return 4;
          else if (reps >= 11) return 3;
          else if (reps >= 9) return 2;
          else return 1;
        }
        return null;
      } else if (field === 'cast-to-horizontal-max-reps') {
        const reps = result['cast-to-horizontal-max-reps'];
        if (reps !== null) {
          if (reps >= 10) return 10;
          else if (reps === 9) return 9;
          else if (reps === 8) return 8;
          else if (reps === 7) return 7;
          else if (reps === 6) return 6;
          else if (reps === 5) return 5;
          else if (reps === 4) return 4;
          else if (reps === 3) return 3;
          else if (reps === 2) return 2;
          else if (reps === 1) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'handstand-hold-wall-max-time') {
        let time = result['handstand-hold-wall-max-time'];
        if (time !== null) {
          time = time / 1000;
          if (time >= 120) return 10;
          else if (time >= 105) return 9;
          else if (time >= 90) return 8;
          else if (time >= 75) return 7;
          else if (time >= 60) return 6;
          else if (time >= 45) return 5;
          else if (time >= 30) return 4;
          else if (time >= 15) return 3;
          else if (time >= 5) return 2;
          else return 1;
        }
        return null;
      } else if (field === 'handstand-hold-wall-shape-points') {
        let points = result['handstand-hold-wall-shape-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'press-to-handstand-floor-max-reps') {
        const reps = result['press-to-handstand-floor-max-reps'];
        if (reps !== null) {
          if (reps >= 5) return 10;
          else if (reps === 4) return 8;
          else if (reps === 3) return 6;
          else if (reps === 2) return 4;
          else if (reps === 1) return 2;
          else return 0;
        }
        return null;
      } else if (field === 'press-to-handstand-floor-shape-points') {
        let points = result['press-to-handstand-floor-shape-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'right-leg-splits-points') {
        let points = result['right-leg-splits-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'left-leg-splits-points') {
        let points = result['left-leg-splits-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'middle-leg-splits-points') {
        let points = result['middle-leg-splits-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'pike-position-points') {
        let points = result['pike-position-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'bridge-position-points') {
        let points = result['bridge-position-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'back-bridge-recover-to-stand-points') {
        let points = result['back-bridge-recover-to-stand-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      }
    }

    if (ageGroup === '9-12yrs') {
      if (field === '20m-sprint-time') {
        let time = result['20m-sprint-time'];
        if (time !== null) {
          time = time / 1000;
          if (time < 3.2) return 10;
          else if (time < 3.3) return 9;
          else if (time < 3.4) return 8;
          else if (time < 3.5) return 7;
          else if (time < 3.6) return 6;
          else if (time < 3.7) return 5;
          else if (time < 3.8) return 4;
          else if (time < 3.9) return 3;
          else if (time < 4.0) return 2;
          else if (time < 4.1) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'rebound-box-jumps-time') {
        let time = result['rebound-box-jumps-time'];
        if (time !== null) {
          time = Math.floor(time / 1000);
          //XXX this is an interpreation of an ambiguous protocol definition
          if (time <= 40) return 10;
          else if (time <= 44) return 9;
          else if (time <= 48) return 8;
          else if (time <= 52) return 7;
          else if (time <= 56) return 6;
          else if (time <= 60) return 5;
          else if (time <= 64) return 4;
          else if (time <= 68) return 3;
          else if (time <= 72) return 2;
          else if (time <= 76) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'half-leg-lifts-max-reps') {
        const reps = result['half-leg-lifts-max-reps'];
        if (reps !== null) {
          if (reps >= 20) return 10;
          else if (reps >= 18) return 9;
          else if (reps >= 16) return 8;
          else if (reps >= 14) return 7;
          else if (reps >= 12) return 6;
          else if (reps >= 10) return 5;
          else if (reps >= 8) return 4;
          else if (reps >= 6) return 3;
          else if (reps >= 4) return 2;
          else if (reps >= 2) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'piked-leg-lifts-timed-reps') {
        const reps = result['piked-leg-lifts-timed-reps'];
        if (reps !== null) {
          if (reps >= 23) return 10;
          else if (reps === 22) return 9;
          else if (reps === 21) return 8;
          else if (reps === 20) return 7;
          else if (reps === 19) return 6;
          else if (reps === 18) return 5;
          else if (reps === 17) return 4;
          else if (reps === 16) return 3;
          else if (reps === 15) return 2;
          else if (reps === 14) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'v-snaps-timed-reps') {
        const reps = result['v-snaps-timed-reps'];
        if (reps !== null) {
          if (reps >= 45) return 10;
          else if (reps >= 43) return 9;
          else if (reps >= 42) return 8;
          else if (reps >= 40) return 7;
          else if (reps >= 38) return 6;
          else if (reps >= 36) return 5;
          else if (reps >= 34) return 4;
          else if (reps >= 32) return 3;
          else if (reps >= 30) return 2;
          else if (reps >= 28) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'rope-climb-time') {
        let time = result['rope-climb-time'];
        if (time !== null) {
          time = time / 1000;
          if (time === 0) return 0;
          else if (time < 10) return 10;
          else if (time < 12) return 9;
          else if (time < 14) return 8;
          else if (time < 16) return 7;
          else if (time < 18) return 6;
          else if (time < 20) return 5;
          else if (time < 22) return 4;
          else if (time < 24) return 3;
          else if (time < 26) return 2;
          else return 0;
        }
        return null;
      } else if (field === 'chin-ups-timed-reps') {
        const reps = result['chin-ups-timed-reps'];
        if (reps !== null) {
          if (reps >= 22) return 10;
          else if (reps >= 20) return 9;
          else if (reps >= 18) return 8;
          else if (reps >= 16) return 7;
          else if (reps >= 15) return 6;
          else if (reps >= 13) return 5;
          else if (reps >= 11) return 4;
          else if (reps >= 9) return 3;
          else if (reps >= 7) return 2;
          else if (reps >= 5) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'cast-to-handstand-max-reps') {
        const reps = result['cast-to-handstand-max-reps'];
        if (reps !== null) {
          if (reps >= 10) return 10;
          else if (reps === 9) return 9;
          else if (reps === 8) return 8;
          else if (reps === 7) return 7;
          else if (reps === 6) return 6;
          else if (reps === 5) return 5;
          else if (reps === 4) return 4;
          else if (reps === 3) return 3;
          else if (reps === 2) return 2;
          else if (reps === 1) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'cast-to-handstand-shape-points') {
        let points = result['cast-to-handstand-shape-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'handstand-hold-beam-max-time') {
        let time = result['handstand-hold-beam-max-time'];
        if (time !== null) {
          time = Math.floor(time / 1000);
          if (time >= 120) return 10;
          else if (time >= 105) return 9;
          else if (time >= 90) return 8;
          else if (time >= 75) return 7;
          else if (time >= 60) return 6;
          else if (time >= 45) return 5;
          else if (time >= 30) return 4;
          else if (time >= 15) return 3;
          else if (time >= 5) return 2;
          else if (time >= 0) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'handstand-hold-beam-shape-points') {
        let points = result['handstand-hold-beam-shape-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'press-to-handstand-beam-max-reps') {
        const reps = result['press-to-handstand-beam-max-reps'];
        if (reps !== null) {
          if (reps >= 10) return 10;
          else if (reps === 9) return 9;
          else if (reps === 8) return 8;
          else if (reps === 7) return 7;
          else if (reps === 6) return 6;
          else if (reps === 5) return 5;
          else if (reps === 4) return 4;
          else if (reps === 3) return 3;
          else if (reps === 2) return 2;
          else if (reps === 1) return 1;
          else return 0;
        }
        return null;
      } else if (field === 'press-to-handstand-beam-shape-points') {
        let points = result['press-to-handstand-beam-shape-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'right-leg-splits-points') {
        let points = result['right-leg-splits-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'left-leg-splits-points') {
        let points = result['left-leg-splits-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'middle-leg-splits-points') {
        let points = result['middle-leg-splits-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'pike-position-points') {
        let points = result['pike-position-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'bridge-position-points') {
        let points = result['bridge-position-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'back-walkover-points') {
        let points = result['back-walkover-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      } else if (field === 'front-walkover-points') {
        let points = result['front-walkover-points'];
        if (points !== null) {
          if (points === '5-correct') return 10;
          else if (points === '4-correct') return 8;
          else if (points === '3-correct') return 6;
          else if (points === '2-correct') return 4;
          else if (points === '1-correct') return 2;
          else return 0;
        }
        return null;
      }
    }

    return null;
  }
}

export default new Fields();
