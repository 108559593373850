import React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Loader, Header, Message } from 'semantic-ui-react';

import FilterPanel from '../components/filter/filter.panel';
import DateHeader from '../components/util/DateHeader';
import ModulesWarning from '../components/modules/modules.warning';

import moment from 'moment';
import 'moment-duration-format';

class Squad extends Component {
  constructor(props) {
    super(props);

    this.moduleRenderers = new Map();
    ['time-trial', 'wag-profile-test'].forEach(id =>
      this.moduleRenderers.set(
        id,
        React.lazy(() =>
          import('../components/modules/' + id + '/results/results.list.js')
        )
      )
    );
  }

  render() {
    const { athletes, modules, clubs } = this.props.stores;

    let rows = [];
    let msg = null;

    if (!clubs.club) {
      msg = <Loader active>Loading club</Loader>;
    } else if (modules.numberOfEnabledResultsModules === 0) {
      msg = <ModulesWarning />;
    } else if (athletes.loading) {
      msg = <Loader active>Loading athletes</Loader>;
    } else if (modules.enabledResultsModules.every(m => m.loading === true)) {
      msg = <Loader active>Loading results</Loader>;
    }

    if (!msg) {
      const athleteIDs = athletes.athletes.map(athlete => athlete.id);

      //XXX
      // const allBestTimes = results.getAthletesBestResults();

      // get complete set of dates from across all modules
      const dates = new Set();
      modules.enabledResultsModules.forEach(m => {
        const datesFromModule = m.getDatesForAthletes(athleteIDs);
        for (const date of datesFromModule.values()) {
          dates.add(date);
        }
      });

      // get results from modules for each date
      let renderedResultsByDate = new Map();
      Array.from(dates)
        .sort()
        .reverse()
        .forEach(date => {
          modules.enabledResultsModules.forEach(m => {
            const results = m.getResultsForDate(date, athleteIDs);
            if (results && results.length > 0) {
              const componentRenderer = this.moduleRenderers.get(m.id);
              if (componentRenderer) {
                if (!renderedResultsByDate.has(date)) {
                  renderedResultsByDate.set(date, []);
                }
                renderedResultsByDate.get(date).push(
                  React.createElement(
                    componentRenderer,
                    {
                      results: results,
                      athletes: athletes,
                      modules: modules,
                      key: [m.id, date].join('-')
                    },
                    null
                  )
                );
              }
            }
          });
        });

      // add rendered results to page
      Array.from(dates)
        .sort()
        .reverse()
        .forEach(d => {
          const components = renderedResultsByDate.get(d);
          if (components && components.length > 0) {
            const date = moment(d, 'YYYY-MM-DD');
            rows.push(<DateHeader date={date} key={date} />);
            rows.push(components);
          }
        });
    }

    return (
      <div>
        <FilterPanel />

        <Header size="large" textAlign="center" style={{ marginTop: '0.4em' }}>
          Squad Results
        </Header>

        {msg}

        {!msg && rows.length === 0 && (
          <Message style={{ marginTop: '2em' }}>No results</Message>
        )}
        {!msg && rows.length > 0 && (
          <React.Suspense fallback={<Loader />}>{rows}</React.Suspense>
        )}
      </div>
    );
  }
}

export default inject('stores')(observer(Squad));
