import { reaction } from 'mobx';

import './server.js';

import auth from './auth.store';
import clubs from './clubs.store';
import settings from './settings.store';
import athletes from './athletes.store';
import filters from './filters.store';
import modules from './modules.store';

const stores = { auth, clubs, settings, filters, athletes, modules };

filters.init();
clubs.init(stores);
athletes.init(stores);

reaction(
  () => auth.user,
  (user, reaction) => {
    if (auth.user) {
      //   console.log('User updated: ' + auth.user.uid);
      settings.subscribe(auth.user);
      clubs.subscribe(auth.user);
      // modules.subscribe(auth.user);
    } else {
      //   console.log('User logged out');
      // modules.unsubscribe();
      clubs.unsubscribe();
      settings.unsubscribe();
    }
  }
);

export default stores;
