import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Label, Item, Header } from 'semantic-ui-react';

import AthleteAvatar from './athlete.avatar';
import AthleteName from './athlete.name';

class AthleteHeader extends Component {
  openAthleteLink = event => {
    if (this.props.link) {
      this.props.history.push('/athletes/' + this.props.athlete.id);
    }
  };

  render() {
    const {
      athlete,
      basic,
      avatarColor,
      avatarBackground,
      hideAvatar
    } = this.props;

    if (basic)
      return (
        <div>
          {!hideAvatar && (
            <AthleteAvatar
              athlete={athlete}
              color={avatarColor}
              background={avatarBackground}
              style={{ marginRight: '0.6em' }}
            />
          )}
          <AthleteName athlete={athlete} />
        </div>
      );

    const tags =
      athlete.tags && athlete.tags.length > 0
        ? athlete.tags.map(t => (
            <Label
              key={t}
              size="small"
              style={{
                marginLeft: 0,
                marginTop: '0.3rem',
                paddingTop: '0.3rem',
                paddingBottom: '0.3rem'
              }}
            >
              {t}
            </Label>
          ))
        : [];

    return (
      <Item onClick={this.openAthleteLink} style={{ marginTop: 0 }}>
        {!hideAvatar && (
          <Item.Image>
            <AthleteAvatar
              athlete={athlete}
              color={avatarColor}
              background={avatarBackground}
            />
          </Item.Image>
        )}

        <Item.Content
          className={tags.length > 0 || athlete.archived ? 'tagged' : null}
        >
          <Header
            style={{
              marginTop: tags.length > 0 || athlete.archived ? 0 : '0.6em'
            }}
          >
            <AthleteName athlete={athlete} />
            {athlete.label && (
              <Header.Subheader>{athlete.label}</Header.Subheader>
            )}
          </Header>
          {!athlete.archived && tags.length > 0 && (
            <Item.Meta style={{ marginTop: 0 }}>{tags}</Item.Meta>
          )}
          {athlete.archived && (
            <Item.Meta style={{ marginTop: 0 }}>
              <Label
                basic
                size={this.props.size === 'huge' ? 'large' : 'small'}
                style={{
                  paddingTop: '0.4em',
                  paddingBottom: '0.4em',
                  marginLeft: 0,
                  marginTop: '0.2em',
                  color: '#573a08',
                  background: '#fffaf3'
                }}
              >
                Archived
              </Label>
            </Item.Meta>
          )}
          {this.props.children}
        </Item.Content>
      </Item>
    );
  }
}

export default withRouter(AthleteHeader);
