import React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';

import AthletePanel from '../components/athletes/athlete.panel';

class Athlete extends Component {
  render() {
    const { id } = this.props.match.params;
    const athlete = this.props.stores.athletes.athletesMap.get(id);

    return <AthletePanel athlete={athlete} />;
  }
}

export default inject('stores')(observer(Athlete));
