import React, { Component } from 'react';
import { ResponsiveBullet } from '@nivo/bullet';

import colors from './colors';

export default class WAGProfileTargetChart extends Component {
  CustomMarker = ({
    index,
    x,
    size,
    color,
    onMouseEnter,
    onMouseMove,
    onMouseLeave
  }) => {
    return (
      <g
        transform={`translate(${x},0)`}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        <circle cy={-8} r={5.2} stroke={color} strokeWidth={2} fill={'none'} />
        {index === 0 && <circle cy={-8} r={2.6} fill={color} />}
        {index === 1 && <circle cy={-8} r={1.8} fill={color} />}
        {index === 2 && <circle cy={-8} r={1.0} fill={color} />}
        <polygon
          points="0,0 6,2 12,0 6,12"
          transform={`translate(-6,-6)`}
          fill={color}
        />
      </g>
    );
  };

  render() {
    const { result, comparisons } = this.props;

    if (!result) return null;

    const targets =
      result.ageGroup === '9-12yrs' ? [30, 60, 75, 100] : [40, 70, 85, 100];

    const markers = [result]
      .concat(
        comparisons
          ? Array.isArray(comparisons)
            ? comparisons.slice(0, 3)
            : [comparisons]
          : []
      )
      .filter(r => r !== null && r !== undefined)
      .map(r => r.scores['total-percent'] * 100);

    return (
      <div style={{ width: '100%', height: '24px' }}>
        <ResponsiveBullet
          data={[
            {
              id: 'Target',
              ranges: targets,
              measures: [],
              markers: markers
            }
          ]}
          margin={{ top: 16, right: 6, bottom: 0, left: 6 }}
          rangeColors={['#F29B9B', '#FFD0B5', '#FFF3C4', '#C1EAC5']}
          markerColors={colors.chart}
          markerComponent={this.CustomMarker}
          titleAlign="start"
          titleOffsetX={-70}
          axisPosition="after"
          animate={false}
        />
      </div>
    );
  }
}
