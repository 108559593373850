import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import firebase from 'firebase';

import {
  Grid,
  Dropdown,
  Button,
  List,
  Responsive,
  Container,
  Icon
} from 'semantic-ui-react';
import AthleteHeader from '../../athletes/athlete.header';

//XXX how should we persist tags that are not used by an athlete? Should we?
class TagsSettingsPanel extends Component {
  ui = observable({
    selectedTag: null
  });

  drafts = observable(new Map());

  options = observable({
    tags: []
  });

  constructor(props) {
    super(props);

    const { athletes: store } = props.stores;

    this.options.tags = store.tags.map(t => {
      return { key: t, text: t, value: t };
    });

    // initialise drafts
    this.revert();
  }

  handleTagAddition = (e, { value }) => {
    this.options.tags = [
      { key: value, text: value, value },
      ...this.options.tags
    ].sort((a, b) => ('' + a.value).localeCompare(b.value));
  };

  handleTagChange = (e, { value }) => (this.ui.selectedTag = value);

  toggleTag = (e, { athlete }) => {
    const tags = this.drafts.get(athlete.id);
    if (tags.includes(this.ui.selectedTag)) {
      tags.remove(this.ui.selectedTag);
    } else {
      tags.push(this.ui.selectedTag);
    }
  };

  selectAll = e => {
    this.drafts.forEach(tags => {
      if (!tags.includes(this.ui.selectedTag)) {
        tags.push(this.ui.selectedTag);
      }
    });
  };

  clearAll = e => {
    this.drafts.forEach(tags => {
      tags.remove(this.ui.selectedTag);
    });
  };

  revert = e => {
    const { activeAthletes: athletes } = this.props.stores.athletes;
    this.drafts.clear();
    athletes.forEach(athlete =>
      this.drafts.set(athlete.id, athlete.tags ? athlete.tags.slice() : [])
    );
  };

  update = e => {
    const { athletes: store } = this.props.stores;
    const { activeAthletes: athletes } = store;
    let updates = []; // {athlete, tags}
    athletes.forEach(athlete => {
      if (this.drafts.has(athlete.id)) {
        const draftTags = this.drafts.get(athlete.id);

        // find new tags in draft and add to the update
        const additions = athlete.tags
          ? draftTags.filter(tag => !athlete.tags.includes(tag))
          : draftTags;
        additions.forEach(addition =>
          updates.push({
            id: athlete.id,
            tags: firebase.firestore.FieldValue.arrayUnion(addition)
          })
        );

        // find missing tags in draft and remove
        if (athlete.tags) {
          const removals = athlete.tags.filter(tag => !draftTags.includes(tag));
          removals.forEach(removal =>
            updates.push({
              id: athlete.id,
              tags: firebase.firestore.FieldValue.arrayRemove(removal)
            })
          );
        }
      }
    });

    store.saveAthletes(updates);
  };

  hasModifiedTags = () => {
    let modified = false;
    const { activeAthletes: athletes } = this.props.stores.athletes;
    athletes.forEach(athlete => {
      const tags = this.drafts.get(athlete.id);
      if (
        tags &&
        (!athlete.tags ||
          tags.includes(this.ui.selectedTag) !==
            athlete.tags.includes(this.ui.selectedTag))
      ) {
        modified = true;
      }
    });
    return modified;
  };

  render() {
    const { activeAthletes: athletes } = this.props.stores.athletes;

    const athleteRows = athletes.map(athlete => {
      const tags = this.drafts.get(athlete.id);
      const hasTag = tags && tags.includes(this.ui.selectedTag);
      const isModified = athlete.tags
        ? tags.includes(this.ui.selectedTag) !==
          athlete.tags.includes(this.ui.selectedTag)
        : hasTag;
      return (
        <List.Item
          key={athlete.id}
          athlete={athlete}
          warning={athlete.archived ? true : null}
          style={{
            cursor: this.ui.selectedTag ? 'pointer' : null,
            backgroundColor: isModified
              ? hasTag
                ? 'rgba(0, 100, 0, 0.1)'
                : 'rgba(100, 0, 0, 0.1)'
              : null,
            padding: '0.5em 1em',
            margin: '0.5em 0px'
          }}
          onClick={this.ui.selectedTag ? this.toggleTag : null}
        >
          {!isModified ? (
            <List.Icon>
              <Icon.Group
                style={{
                  paddingTop: '0.3em',
                  visibility: hasTag ? 'visible' : 'hidden'
                }}
              >
                <Icon color="green" name="tag" size="large" />
              </Icon.Group>
            </List.Icon>
          ) : (
            <List.Icon>
              <Icon.Group
                style={{
                  paddingTop: '0.3em'
                }}
              >
                <Icon
                  color={hasTag ? 'green' : 'red'}
                  name="tag"
                  size="large"
                />
                <Icon
                  corner
                  name={hasTag ? 'add' : 'minus'}
                  color={hasTag ? 'green' : 'red'}
                />
              </Icon.Group>
            </List.Icon>
          )}
          <List.Content>
            <AthleteHeader athlete={athlete} basic />
          </List.Content>
        </List.Item>
      );
    });

    const groupButtons = [
      <Button
        basic
        compact
        onClick={this.selectAll}
        style={{ boxShadow: 'none', padding: 0, marginRight: '0.5em' }}
        disabled={this.ui.selectedTag === null}
        key={0}
      >
        Select all
      </Button>,
      <span style={{ color: 'lightgrey' }} key={1}>
        |
      </span>,
      <Button
        basic
        compact
        onClick={this.clearAll}
        style={{
          boxShadow: 'none',
          padding: 0,
          marginLeft: '0.5em',
          marginRight: '0.5em'
        }}
        disabled={this.ui.selectedTag === null}
        key={2}
      >
        Clear all
      </Button>,
      <span style={{ color: 'lightgrey' }} key={3}>
        |
      </span>,
      <Button
        basic
        compact
        onClick={this.revert}
        style={{ boxShadow: 'none', padding: 0, marginLeft: '0.5em' }}
        disabled={!this.hasModifiedTags()}
        key={4}
      >
        Revert
      </Button>
    ];

    return (
      <div>
        <Dropdown
          options={this.options.tags}
          placeholder="Select or create tag"
          search
          selection
          fluid
          button
          allowAdditions
          additionLabel="Add tag: "
          noResultsMessage="No tags found. Type to create a new one."
          value={this.ui.selectedTag}
          onAddItem={this.handleTagAddition}
          onChange={this.handleTagChange}
        />

        <Responsive as={Container} maxWidth={399}>
          <Grid
            verticalAlign="middle"
            style={{ marginTop: 0, marginBottom: '0.5em' }}
          >
            <Grid.Row textAlign="center">
              <Button
                primary
                fluid
                onClick={this.update}
                disabled={!this.hasModifiedTags()}
              >
                Update
              </Button>
            </Grid.Row>
            <Grid.Row textAlign="center">
              <div style={{ width: '100%', textAlign: 'center' }}>
                {groupButtons}
              </div>
            </Grid.Row>
          </Grid>
        </Responsive>

        <Responsive minWidth={400}>
          <Grid
            verticalAlign="middle"
            style={{ marginTop: 0, marginBottom: '0.5em' }}
          >
            <Grid.Row>
              <Grid.Column width={10} style={{ paddingLeft: '1.2em' }}>
                <div style={{ width: '100%', textAlign: 'left' }}>
                  {groupButtons}
                </div>
              </Grid.Column>
              <Grid.Column
                width={6}
                textAlign="right"
                style={{ padding: null }}
              >
                <Button
                  primary
                  onClick={this.update}
                  disabled={!this.hasModifiedTags()}
                >
                  Update
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>

        <List>{athleteRows}</List>
      </div>
    );
  }
}

export default inject('stores')(observer(TagsSettingsPanel));
