import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Segment, Header } from 'semantic-ui-react';

import ModulesSettingsSection from './modules.settings.section';

//XXX need to disable module changes for non-admins
class ModulesSettingsPanel extends Component {
  render() {
    const { modules: modulesStore, clubs: clubsStore } = this.props.stores;

    let sections = [];

    modulesStore.modules
      .filter(module => module.id !== 'calculated-splits')
      .forEach(module => {
        sections.push(
          <Segment key={module.id}>
            <ModulesSettingsSection
              module={module}
              disabled={!clubsStore.isAdmin}
            />
          </Segment>
        );

        //XXX special case for calculated-splits (as a dependent module) - needs to be generallised
        if (
          module.id === 'time-trial' &&
          modulesStore.hasModule('calculated-splits')
        ) {
          sections.push(
            <Segment key={'calculated-splits'}>
              <ModulesSettingsSection
                module={modulesStore.getModule('calculated-splits')}
                disabled={
                  !clubsStore.isAdmin ||
                  !modulesStore.isModuleEnabled(module.id)
                }
              />
            </Segment>
          );
        }
      });

    return (
      <Segment.Group>
        <Segment secondary>
          <Header>Club Modules</Header>
        </Segment>
        {sections}
      </Segment.Group>
    );
  }
}

export default inject('stores')(observer(ModulesSettingsPanel));
