import { observable, action } from 'mobx';
import firebase from 'firebase';

var athleteStore = observable({
  loading: true,
  athletesMap: observable(new Map()),

  db: firebase.firestore(),
  collection: null,
  closeDBSession: null,

  athleteListeners: [],

  filters: null,

  init({ filters }) {
    this.filters = filters;
  },

  subscribe(clubID) {
    // console.log(user);
    if (this.closeDBSession) this.unsubscribe();
    this.collection = this.db.collection('athletes');

    this.closeDBSession = this.collection
      .where('club', '==', clubID)
      .orderBy('last_name')
      .orderBy('first_name')
      .onSnapshot(
        { includeMetadataChanges: true },
        function(snapshot) {
          snapshot.docChanges().forEach(
            function(change) {
              const id = change.doc.ref.id;
              const data = change.doc.data();
              // console.log(change.type + ': ' + id);
              // console.log(data);
              if (change.type === 'added' || change.type === 'modified') {
                data.id = id;
                this.athletesMap.set(id, data);
                if (!change.doc.metadata.hasPendingWrites) {
                  this.athleteListeners.forEach(cb => cb(id, data));
                }
              } else if (change.type === 'removed') {
                this.athletesMap.delete(id);
                this.athleteListeners.forEach(cb => cb(id, data, true));
              }
            }.bind(this),
            function(error) {
              //XXX this needs handling better
              console.error(
                'Error accessing athlete data for club ' +
                  clubID +
                  ': ' +
                  error.message
              );
            }
          );
          this.loading = false;
        }.bind(this)
      );
  },

  unsubscribe() {
    this.athletesMap.clear();
    if (this.closeDBSession) {
      this.closeDBSession();
    }
    this.collection = null;
  },

  addAthleteListener(cb) {
    if (!this.athleteListeners.includes(cb)) this.athleteListeners.push(cb);
  },

  removeAthleteListener(cb) {
    var index = this.athleteListeners.indexOf(cb);
    if (index > -1) this.athleteListeners.splice(index, 1);
  },

  sortByName(a, b) {
    if (a.last_name === b.last_name) {
      return a.first_name.localeCompare(b.first_name);
    }
    return a.last_name.localeCompare(b.last_name);
  },

  get size() {
    return this.athletesMap.size;
  },

  getAthlete(id) {
    return this.athletesMap.get(id);
  },

  // returns list of all athletes, ignoring all filters
  get allAthletes() {
    return Array.from(this.athletesMap.values()).sort(this.sortByName);
  },

  // returns list of athletes, accounting for filters
  get athletes() {
    return this.allAthletes.filter(athlete => {
      if (!this.filters.get('showArchived') && athlete.archived) return false;

      let tagFilter = this.filters.get('tags');
      if (tagFilter) {
        if (!Array.isArray(tagFilter)) tagFilter = [tagFilter];
        if (tagFilter.length > 0) {
          if (!athlete.tags || athlete.tags.length === 0) return false;
          if (!tagFilter.every(tag => athlete.tags.includes(tag))) return false;
        }
      }

      let genderFilter = this.filters.get('gender');
      if (genderFilter) {
        if (!Array.isArray(genderFilter)) genderFilter = [genderFilter];
        if (!genderFilter.includes(athlete.gender)) return false;
      }

      //XXX how do we filter out athletes by results from here?
      //XXX eg how do we only show list of athletes that have 300m results?
      //XXX ignore for now

      return true;
    });
  },

  // returns list of all active (not archived) athletes, ignoring all filters
  get activeAthletes() {
    return this.allAthletes.filter(a => !a.archived);
  },

  // returns list of all archived athletes, ignoring all filters
  get archivedAthletes() {
    return this.allAthletes.filter(a => a.archived);
  },

  get tags() {
    const uniqTags = this.allAthletes.flatMap(a => (a.tags ? a.tags : []));
    return [...new Set(uniqTags)].sort();
  },

  saveAthlete: action(function(athlete) {
    let details = {};
    if (athlete.first_name !== undefined)
      details.first_name = athlete.first_name;
    if (athlete.last_name !== undefined) details.last_name = athlete.last_name;
    if (athlete.gender !== undefined) details.gender = athlete.gender;
    if (athlete.photo_url !== undefined) details.photo_url = athlete.photo_url;
    if (athlete.club !== undefined) details.club = athlete.club;
    if (athlete.label !== undefined) details.label = athlete.label;
    if (athlete.tags !== undefined) details.tags = athlete.tags;
    if (athlete.archived !== undefined) details.archived = athlete.archived;

    if (athlete.id) {
      details.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      this.collection
        .doc(athlete.id)
        .update(details)
        .then(() => {
          console.log('Athlete updated with ID: ', athlete.id);
        })
        .catch(error => {
          //XXX error handling
          console.error('Error updating athlete: ', error);
        });
    } else {
      details.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      this.collection
        .add(details)
        .then(docRef => {
          console.log('Athlete created with ID: ', docRef.id);
        })
        .catch(error => {
          //XXX error handling
          console.error('Error creating athlete: ', error);
        });
    }
  }),

  // array of athlete details
  saveAthletes: action(function(athletes) {
    var batch = this.db.batch();

    athletes.forEach(athlete => {
      let details = {};
      if (athlete.first_name !== undefined)
        details.first_name = athlete.first_name;
      if (athlete.last_name !== undefined)
        details.last_name = athlete.last_name;
      if (athlete.gender !== undefined) details.gender = athlete.gender;
      if (athlete.photo_url !== undefined)
        details.photo_url = athlete.photo_url;
      if (athlete.club !== undefined) details.club = athlete.club;
      if (athlete.label !== undefined) details.label = athlete.label;
      if (athlete.tags !== undefined) details.tags = athlete.tags;
      if (athlete.archived !== undefined) details.archived = athlete.archived;

      if (athlete.id) {
        details.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        batch.update(this.collection.doc(athlete.id), details);
      } else {
        //XXX untested
        details.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        batch.set(this.collection.doc(), details);
      }
    });

    batch
      .commit()
      .then(function() {
        console.log(
          'Successfully created/updated ' + athletes.length + ' athletes'
        );
      })
      .catch(error => {
        //XXX error handling
        console.error(
          'Error creatin/updating ' + athletes.length + ' athletes'
        );
      });
  }),

  deleteAthlete: action(function(athlete) {
    if (athlete && athlete.id) {
      this.collection
        .doc(athlete.id)
        .delete()
        .then(function() {
          console.log('Athlete successfully deleted!');
        })
        .catch(function(error) {
          //XXX
          console.error('Error removing athlete: ', error);
        });
    }
  })
});

export default athleteStore;
