import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { Grid, Header, Icon, Loader } from 'semantic-ui-react';

class ModulesSettingsSection extends Component {
  ui = observable({ descriptionOpen: false });

  toggleDescriptionOpen = (e, { moduleid }) => {
    this.ui.descriptionOpen = !this.ui.descriptionOpen;
  };

  toggleModuleEnabled = (e, { moduleid }) => {
    const { modules } = this.props.stores;
    modules.toggleModuleEnabled(moduleid);
  };

  render() {
    const { module, disabled: sectionDisabled } = this.props;

    const moduleEnabled = this.props.stores.modules.isModuleEnabled(module.id);

    return (
      <div
        style={{
          marginTop: '0.8rem'
        }}
        key={module.id}
      >
        <Grid>
          <Grid.Row style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
            <Grid.Column width={module.description ? 14 : 16}>
              <Header
                size="tiny"
                disabled={sectionDisabled}
                style={{ marginBottom: '0.4rem' }}
              >
                <Icon
                  link={!sectionDisabled}
                  name="toggle on"
                  color={moduleEnabled && !sectionDisabled ? 'blue' : 'grey'}
                  style={moduleEnabled ? {} : { transform: 'scaleX(-1)' }}
                  onClick={!sectionDisabled ? this.toggleModuleEnabled : null}
                  moduleid={module.id}
                ></Icon>
                {module.name}
              </Header>
            </Grid.Column>
            {module.description && (
              <Grid.Column width={2} textAlign="right">
                <Icon
                  link
                  name="info circle"
                  color="grey"
                  onClick={this.toggleDescriptionOpen}
                  moduleid={module.id}
                ></Icon>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>

        {module.description && (
          <p
            style={{
              color: 'grey',
              display: this.ui.descriptionOpen ? 'inherit' : 'none',
              marginTop: '1rem'
            }}
          >
            {module.description}
          </p>
        )}

        {moduleEnabled && !sectionDisabled && (
          <React.Suspense fallback={<Loader />}>
            {React.createElement(
              React.lazy(() =>
                import(
                  '../modules/' +
                    module.id +
                    '/settings/module.settings.panel.js'
                )
              ),
              { module: module },
              null
            )}
          </React.Suspense>
        )}
      </div>
    );
  }
}

export default inject('stores')(observer(ModulesSettingsSection));
