import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Loader, Header, Button } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';

import ModulesWarning from '../components/modules/modules.warning';

class DataEntryPage extends Component {
  constructor(props) {
    super(props);

    this.moduleRenderers = new Map();
    ['checkins', 'time-trial', 'wag-profile-test'].forEach(id =>
      this.moduleRenderers.set(
        id,
        React.lazy(() =>
          import('../components/modules/' + id + '/new.data.panel.js')
        )
      )
    );
  }

  render() {
    const { athletes, modules } = this.props.stores;

    if (athletes.loading) {
      return <Loader active>Loading data</Loader>;
    }

    if (this.props.match.params.module) {
      return (
        <React.Suspense fallback={<Loader />}>
          {React.createElement(
            this.moduleRenderers.get(this.props.match.params.module),
            {},
            null
          )}
        </React.Suspense>
      );
    }

    if (modules.numberOfEnabledResultsModules === 1) {
      return <Redirect to={'/data/' + modules.enabledResultsModules[0].id} />;
    } else if (modules.numberOfEnabledResultsModules > 1) {
      const links = modules.enabledResultsModules.map(module => (
        <Link to={'/data/' + module.id} key={module.id}>
          <Button
            size="large"
            fluid
            style={{ marginBottom: '1em' }}
            key={module.id}
          >
            {module.name}
          </Button>
        </Link>
      ));
      return (
        <div>
          <Header
            size="large"
            textAlign="center"
            style={{ marginTop: '0.4em' }}
          >
            Add Results
          </Header>
          {links}
        </div>
      );
    }

    //default when no modules are enabled
    return (
      <div>
        <ModulesWarning style={{ marginTop: '2rem' }} />
      </div>
    );
  }
}

export default inject('stores')(observer(DataEntryPage));
