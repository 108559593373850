import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { Icon, Table, Button } from 'semantic-ui-react';

import AthleteModal from './athlete.modal';

class AthletesSettingsPanel extends Component {
  render() {
    const { allAthletes: athletes } = this.props.stores.athletes;

    let athleteRows = athletes.map(athlete => (
      <AthleteModal athlete={athlete} key={athlete.id}>
        <Table.Row warning={athlete.archived ? true : null}>
          <Table.Cell>
            {athlete.first_name + ' ' + athlete.last_name}
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Icon name="edit" />
          </Table.Cell>
        </Table.Row>
      </AthleteModal>
    ));

    return (
      <Table unstackable>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="2">
              <AthleteModal>
                <Button
                  floated="right"
                  icon
                  labelPosition="left"
                  primary
                  size="small"
                >
                  <Icon name="user plus" /> Add Athlete
                </Button>
              </AthleteModal>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{athleteRows}</Table.Body>
      </Table>
    );
  }
}

export default inject('stores')(AthletesSettingsPanel);
