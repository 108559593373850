import React from 'react';
import { Component } from 'react';

import { Item } from 'semantic-ui-react';

import AthleteHeader from '../athletes/athlete.header';

class ProfileHeader extends Component {
  render() {
    const { profile } = this.props;

    if (!profile) return null;

    const names = profile.displayName ? profile.displayName.split(/\s+/) : [];

    return (
      <Item.Group unstackable className="athleteItem">
        <AthleteHeader
          athlete={{
            first_name: names.length > 0 ? names[0] : null,
            last_name: names.length > 1 ? names[1] : null,
            photo_url: profile.photoURL,
            label: profile.email
          }}
          size="huge"
          textAlign="center"
          style={{ paddingBottom: '0.5em' }}
        />
      </Item.Group>
    );
  }
}

export default ProfileHeader;
