import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

export default class Improvement extends Component {
  render() {
    const { value, comparison, absolute, style } = this.props;

    if (typeof value !== 'number' || typeof comparison !== 'number') {
      return null;
    }

    const improvement = absolute
      ? value - comparison
      : ((value - comparison) * 100) / comparison;

    const label = absolute
      ? Math.abs(improvement).toString()
      : Math.abs(improvement).toFixed(1) + '%';

    const icon = improvement < 0 ? 'arrow down' : 'arrow up';

    var mergedStyle = Object.assign(
      {
        fontSize: '1rem',
        color: improvement < 0 ? '#BA2525' : '#2F8132'
      },
      style
    );

    return (
      <span style={mergedStyle}>
        <Icon name={icon} />
        {label}
      </span>
    );
  }
}
