import { observable, action } from 'mobx';
import firebase from 'firebase';
import fields from '../../components/modules/wag-profile-test/fields/fields';

var wagProfileTestResultsStore = observable({
  id: null,
  name: null,
  description: null,
  capabilities: null,

  settings: null,
  settingsRef: null,
  closeSettingsDBSession: null,

  resultsRefs: new Map(),
  resultsDB: firebase
    .firestore()
    .collection('modules')
    .doc('wag-profile-test')
    .collection('results'),

  results: observable(new Map()),
  resultsByAthlete: observable(new Map()),
  resultsByDate: observable(new Map()),

  // key: athlete id
  // value: boolean (true if initial data load is yet to be received)
  resultsLoadingForAthlete: new Map(),

  // resultListeners: [],

  filters: null,

  init(data, { modules, athletes, filters }) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.capabilities = data.capabilities;
    this.filters = filters;
    athletes.addAthleteListener(this.subscribeToAthleteResults.bind(this));
  },

  athleteUpdated(athleteID, data, removed) {
    if (removed) {
      this.unsubscribeToAthleteResults(athleteID);
    } else {
      this.subscribeToAthleteResults(athleteID);
    }
  },

  subscribe(clubID) {
    this.settingsRef = firebase
      .firestore()
      .collection('modules')
      .doc(this.id)
      .collection('settings')
      .doc(clubID);

    this.closeSettingsDBSession = this.settingsRef.onSnapshot(
      function(doc) {
        this.settings = doc.data();
      }.bind(this),
      function(error) {
        console.error(
          'Error accessing wag-profile-test settings data: ' + error.message
        );
      }
    );
  },

  unsubscribe() {
    if (this.closeSettingsDBSession) {
      this.closeSettingsDBSession();
    }
    this.settingsRef = null;
    this.settings = null;
  },

  subscribeToAthleteResults(athleteID, data, removed) {
    // console.log(athleteID);
    if (removed) {
      this.unsubscribeToAthleteResults(athleteID);
    } else if (!this.resultsRefs.has(athleteID)) {
      this.resultsLoadingForAthlete.set(athleteID, true);
      this.resultsByAthlete.set(athleteID, new Map());
      this.resultsRefs.set(
        athleteID,
        this.resultsDB.where('athlete', '==', athleteID).onSnapshot(
          { includeMetadataChanges: true },
          function(snapshot) {
            snapshot.docChanges().forEach(
              function(change) {
                const id = change.doc.ref.id;
                const doc = change.doc.data();

                var athlete = this.resultsByAthlete.get(doc.athlete);
                if (!this.resultsByDate.has(doc.date)) {
                  this.resultsByDate.set(doc.date, new Map());
                }
                var date = this.resultsByDate.get(doc.date);

                if (change.type === 'added' || change.type === 'modified') {
                  doc.id = id;
                  doc.hasPendingWrites = change.doc.metadata.hasPendingWrites;

                  if (doc.data) {
                    doc.scores = fields.calculateScores(doc.data);
                    // console.log(
                    //   '%cAthlete: ' + doc.id,
                    //   'color: blue; font-size: large'
                    // );
                    // Object.getOwnPropertyNames(doc.scores).forEach(field =>
                    //   console.log(field + ' : ' + doc.scores[field])
                    // );
                  }

                  this.results.set(id, doc); // complete list of results for all subscribed athletes
                  athlete.set(id, doc); // results for the specific athlete
                  date.set(id, doc); // results for the specific date (across all athletes)

                  // this.resultListeners.forEach(l => l(change.type, data));
                } else if (change.type === 'removed') {
                  // const result = this.results.get(id);
                  this.results.delete(id);
                  athlete.delete(id);
                  date.delete(id);

                  // if (result) {
                  //   this.resultListeners.forEach(l => l(change.type, result));
                  // }
                }
              }.bind(this)
            );
            this.resultsLoadingForAthlete.set(athleteID, false);
          }.bind(this),
          function(error) {
            //XXX this needs handling better
            console.error(
              'Error accessing wag-profile-test data for athlete ' +
                athleteID +
                ': ' +
                error.message
            );
          }
        )
      );
    }
  },

  unsubscribeToAthleteResults(athleteID) {
    const ref = this.resultsRefs.get(athleteID);
    if (ref) ref();
    this.resultsRefs.delete(athleteID);

    if (this.resultsByAthlete.has(athleteID)) {
      this.resultsByAthlete.get(athleteID).forEach(result => {
        this.results.delete(result.id);
        this.resultsByDate.get(result.date).delete(result.id);
      });
      this.resultsByAthlete.delete(athleteID);
    }
    this.resultsLoadingForAthlete.delete(athleteID);
  },

  get loading() {
    return Array.from(this.resultsLoadingForAthlete.values()).includes(true);
  },

  areAthleteResultsLoading(athleteID) {
    return (
      !this.resultsLoadingForAthlete.has(athleteID) ||
      this.resultsLoadingForAthlete.get(athleteID) === true
    );
  },

  get allResults() {
    return this.results;
  },

  getResultsForAthlete(athleteID) {
    return this.getUnfilteredResultsForAthlete(athleteID).filter(result =>
      this.isResultIncluded(result)
    );
  },

  getUnfilteredResultsForAthlete(athleteID) {
    const results = this.resultsByAthlete.get(athleteID);
    if (results) return Array.from(results.values());
    return [];
  },

  //XXX needs to incorporate date filter
  get dates() {
    return Array.from(this.resultsByDate.keys()).sort();
  },

  get unfilteredDates() {
    return Array.from(this.resultsByDate.keys()).sort();
  },

  getDatesForAthlete(id) {
    const uniqueDates = new Set();
    this.getResultsForAthlete(id).forEach(r => uniqueDates.add(r.date));
    return uniqueDates;
  },

  getDatesForAthletes(athleteIDs) {
    const uniqueDates = new Set();
    athleteIDs.forEach(id => {
      this.getResultsForAthlete(id).forEach(r => uniqueDates.add(r.date));
    });
    return uniqueDates;
  },

  getResultsForAthleteAndDate(athleteID, date) {
    const results = this.getResultsForDate(date)
      .filter(result => result.athlete === athleteID)
      .sort((a, b) => a.distance - b.distance);
    return results;
  },

  getResultsForDate(date, athleteIDs) {
    //XXX handle different date formats

    if (!athleteIDs) athleteIDs = Array.from(this.resultsByAthlete.keys());
    const results = this.resultsByDate.get(date);
    if (results)
      return Array.from(results.values()).filter(
        result =>
          athleteIDs.includes(result.athlete) && this.isResultIncluded(result)
      );
    return [];
  },

  saveResults: action(function(resultUpdates) {
    resultUpdates.forEach(result => {
      const dataFields = Object.entries(result).filter(
        field =>
          field[0] !== 'id' && field[0] !== 'athlete' && field[0] !== 'date'
      );

      const updateHasData =
        dataFields.length > 0 && !dataFields.every(d => d[1] === null);

      if (!updateHasData && result.id) {
        const storedResult = this.results.get(result.id);

        // all (draft) dataFields are empty, so check the remaining server fields
        // if they are all empty then delete the entire record
        if (
          Object.getOwnPropertyNames(storedResult.data).length === 0 ||
          Object.entries(storedResult.data)
            .filter(d => !Object.getOwnPropertyNames(result).includes(d[0]))
            .every(d => d[1] === null)
        ) {
          // delete entire result from server
          this.resultsDB
            .doc(result.id)
            .delete()
            .then(function() {
              console.log('Result successfully deleted!');
            })
            .catch(function(error) {
              console.error('Error removing result: ', error);
            });
        }
      }

      if (result.id) {
        // id already exists so this is updating an existing server record
        let update = {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        };
        dataFields.forEach(d => {
          update['data.' + d[0]] =
            d[1] === null ? firebase.firestore.FieldValue.delete() : d[1];
        });
        this.resultsDB.doc(result.id).update(update);
        //XXX error handling
      } else {
        // no id so this is a new record
        let update = {
          athlete: result.athlete,
          date: result.date,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          data: Object.fromEntries(
            dataFields.map(d => [
              d[0],
              d[1] === null ? firebase.firestore.FieldValue.delete() : d[1]
            ])
          )
        };
        this.resultsDB
          .add(update)
          .then(function(docRef) {
            console.log('Result created with ID: ', docRef.id);
          })
          .catch(function(error) {
            console.error('Error creating result: ', error);
          });
      }
    });
  }),

  // return true if the result meets filter conditions
  isResultIncluded(result) {
    //XXX no filter implementation yet

    return true;
  }
});

export default wagProfileTestResultsStore;
