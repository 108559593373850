import { observable, action } from 'mobx';
import firebase from 'firebase';

import timeTrialModule from './modules/time-trial.store';
import calculatedSplitsModule from './modules/calculated-splits.store';
import wagProfileTestModule from './modules/wag-profile-test.store';
import checkins from './modules/checkins.store';

//XXX modules should be loaded based on setting store, rather than the user directly
//XXX modules should be able to register filters, rather than relying on the filter store to implement everything
//XXX modules need to be able to define themselves as primary and/or have dependcies on other modules
var modulesStore = observable({
  loading: true,
  modulesMap: null,

  db: firebase.firestore().collection('modules'),
  closeDBSession: null,

  // user: null,
  stores: null,
  club: null,
  // settings: null,

  moduleListeners: null,

  initialised: false,

  init(stores) {
    this.modulesMap = new Map();
    this.moduleListeners = [];

    this.stores = stores;
    // this.settings = settings;

    this.closeDBSession = this.db.onSnapshot(
      // { includeMetadataChanges: true },
      function(snapshot) {
        snapshot.docChanges().forEach(
          function(change) {
            const id = change.doc.ref.id;
            const data = change.doc.data();
            // console.log(change.type + ': ' + id);
            // console.log(data);
            if (change.type === 'added' || change.type === 'modified') {
              data.id = id;
              let module = null;
              switch (id) {
                case 'time-trial':
                  module = timeTrialModule;
                  break;
                case 'calculated-splits':
                  module = calculatedSplitsModule;
                  break;
                case 'wag-profile-test':
                  module = wagProfileTestModule;
                  break;
                case 'checkins':
                  module = checkins;
                  break;
                default:
                  break;
              }
              if (module) {
                module.init(data, this.stores);
                this.modulesMap.set(id, module);
                this.moduleListeners.forEach(cb => cb(id, module));
                if (this.club) module.subscribe(this.club);
              }
            } else if (change.type === 'removed') {
              //XXX de-init module?
              this.modulesMap.delete(id);
            }
          }.bind(this)
        );
        this.loading = false;
        // if (this.user) this.subscribe(this.user);
      }.bind(this),
      function(error) {
        console.error('Error accessing module data: ' + error.message);
      }
    );
    this.initialised = true;
  },

  get isInitialised() {
    return this.initialised;
  },

  subscribe(club) {
    if (this.club !== club) this.unsubscribe();
    this.club = club;

    // for each registered module (this.modulesMap) subscribe to user's doc in 'settings' collection
    //XXX should will need adjusting for club config
    this.modulesMap.forEach((module, moduleID) => {
      module.subscribe(club);
    });
  },

  unsubscribe() {
    this.club = null;
    this.modulesMap.forEach((module, moduleID) => {
      module.unsubscribe();
    });
    //XXX
    // if (this.closeDBSession) {
    //   this.closeDBSession();
    // }
  },

  addModuleListener(cb) {
    if (!this.moduleListeners.includes(cb)) this.moduleListeners.push(cb);
  },

  removeModuleListener(cb) {
    var index = this.moduleListeners.indexOf(cb);
    if (index > -1) this.moduleListeners.splice(index, 1);
  },

  get modules() {
    return Array.from(this.modulesMap.values()).filter(module =>
      this.stores.clubs.account.available_modules.includes(module.id)
    );
  },

  hasModule(id) {
    return (
      this.stores.clubs.account.available_modules.includes(id) &&
      this.modulesMap.has(id)
    );
  },

  getModule(id) {
    return this.stores.clubs.account &&
      this.stores.clubs.account.available_modules.includes(id)
      ? this.modulesMap.get(id)
      : null;
  },

  //XXX filtering out calculated splits for now (as a secondary module)
  get enabledModules() {
    return this.stores.clubs.account && this.club
      ? Array.from(this.modulesMap.values()).filter(module =>
          this.stores.clubs.isModuleEnabled(module.id)
        )
      : [];
  },

  enabledModulesWithCapability(capability) {
    return this.stores.clubs.account && this.club
      ? Array.from(this.modulesMap.values()).filter(
          module =>
            module.capabilities[capability] &&
            this.stores.clubs.isModuleEnabled(module.id)
        )
      : [];
  },

  get enabledResultsModules() {
    return this.enabledModulesWithCapability('results');
  },

  get numberOfEnabledModules() {
    return this.enabledModules.length;
  },

  get numberOfEnabledResultsModules() {
    return this.enabledModulesWithCapability('results').length;
  },

  isModuleEnabled(id) {
    if (this.modulesMap.has(id)) return this.stores.clubs.isModuleEnabled(id);
    return null;
  },

  // modules are enabled in the club settings
  toggleModuleEnabled(id) {
    this.stores.clubs.toggleModuleEnabled(id);
  },

  // module specific settings are handled by the module itself
  toggleModuleSetting: action(function(id, field) {
    const module = this.modulesMap.get(id);
    if (module) {
      module.toggleModuleSetting(field);
    }
  })
});

export default modulesStore;
