import React from 'react';
import { Component } from 'react';
import { Segment, Button, Divider } from 'semantic-ui-react';

import ProfileHeader from './profile.header';

class WaitlistedUserPanel extends Component {
  render() {
    const { auth } = this.props;

    return (
      <Segment textAlign="center">
        <div style={{ fontSize: '1.3em' }}>
          <p>Thank you for registering with Trackletic.</p>
          <p>
            We are currently in limited beta. You have been added to our
            waitlist and we will contact you when your account has been enabled.
          </p>
        </div>
        <Divider />
        <ProfileHeader profile={auth.profile} />
        <Button basic onClick={auth.signOut}>
          Sign out
        </Button>
      </Segment>
    );
  }
}

export default WaitlistedUserPanel;
