import { observable, action } from 'mobx';
import firebase from 'firebase';

//XXX this should maintain PBs internally rather than rely on components to determine for themselves
var timeTrialResultsStore = observable({
  id: null,
  name: null,
  description: null,
  capabilities: null,

  settings: null,
  settingsRef: null,
  closeSettingsDBSession: null,

  resultsRefs: new Map(),
  resultsDB: firebase
    .firestore()
    .collection('modules')
    .doc('time-trial')
    .collection('results'),

  results: observable(new Map()),
  resultsByAthlete: observable(new Map()),
  resultsByDate: observable(new Map()),

  // key: athlete id
  // value: boolean (true if initial data load is yet to be received)
  resultsLoadingForAthlete: new Map(),

  resultListeners: [],

  filters: null,

  init(data, { modules, athletes, filters }) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.capabilities = data.capabilities;
    this.filters = filters;
    athletes.addAthleteListener(this.subscribeToAthleteResults.bind(this));
  },

  athleteUpdated(athleteID, data, removed) {
    if (removed) {
      this.unsubscribeToAthleteResults(athleteID);
    } else {
      this.subscribeToAthleteResults(athleteID);
    }
  },

  subscribe(clubID) {
    this.settingsRef = firebase
      .firestore()
      .collection('modules')
      .doc(this.id)
      .collection('settings')
      .doc(clubID);

    this.closeSettingsDBSession = this.settingsRef.onSnapshot(
      function(doc) {
        this.settings = doc.data();
      }.bind(this),
      function(error) {
        console.error(
          'Error accessing time-trial module settings: ' + error.message
        ); //XXX
      }
    );
  },

  subscribeToAthleteResults(athleteID, data, removed) {
    // console.log(athleteID);
    if (removed) {
      this.unsubscribeToAthleteResults(athleteID);
    } else if (!this.resultsRefs.has(athleteID)) {
      this.resultsLoadingForAthlete.set(athleteID, true);
      this.resultsByAthlete.set(athleteID, new Map());
      this.resultsRefs.set(
        athleteID,
        this.resultsDB.where('athlete', '==', athleteID).onSnapshot(
          { includeMetadataChanges: true },
          function(snapshot) {
            snapshot.docChanges().forEach(
              function(change) {
                const id = change.doc.ref.id;
                const data = change.doc.data();
                // console.log(change.type + ': ' + id);
                // console.log(data);

                var athlete = this.resultsByAthlete.get(data.athlete);
                if (!this.resultsByDate.has(data.date)) {
                  this.resultsByDate.set(data.date, new Map());
                }
                var date = this.resultsByDate.get(data.date);

                if (change.type === 'added' || change.type === 'modified') {
                  data.id = id;
                  data.hasPendingWrites = change.doc.metadata.hasPendingWrites;

                  this.results.set(id, data); // complete list of results for all subscribed athletes
                  athlete.set(id, data); // results for the specific athlete
                  date.set(id, data); // results for the specific date (across all athletes)

                  this.resultListeners.forEach(l => l(change.type, data));
                } else if (change.type === 'removed') {
                  const result = this.results.get(id);
                  this.results.delete(id);
                  athlete.delete(id);
                  date.delete(id);

                  if (result) {
                    this.resultListeners.forEach(l => l(change.type, result));
                  }
                }
              }.bind(this)
            );
            this.resultsLoadingForAthlete.set(athleteID, false);
          }.bind(this),
          function(error) {
            console.error(
              'Error accessing time-trial data for athlete: ' + error.message
            );
          }
        )
      );
    }
  },

  unsubscribeToAthleteResults(athleteID) {
    const ref = this.resultsRefs.get(athleteID);
    if (ref) ref();
    this.resultsRefs.delete(athleteID);

    if (this.resultsByAthlete.has(athleteID)) {
      this.resultsByAthlete.get(athleteID).forEach(result => {
        this.results.delete(result.id);
        this.resultsByDate.get(result.date).delete(result.id);
      });
      this.resultsByAthlete.delete(athleteID);
    }
    this.resultsLoadingForAthlete.delete(athleteID);
  },

  unsubscribe() {
    this.resultsRefs.forEach((athleteID, ref) => {
      if (ref) ref();
    });
    this.resultsRefs.clear();
    this.results.clear();
    this.resultsByAthlete.clear();
    this.resultsByDate.clear();
    this.resultsLoadingForAthlete.clear();

    if (this.closeSettingsDBSession) {
      this.closeSettingsDBSession();
    }
    this.settingsRef = null;
    this.settings = null;
  },

  unsubscribeToResultsByAthlete(athleteID) {
    // console.log('Unsubscribing to results for athlete ' + athleteID);
    const ref = this.resultsRefs.get(athleteID);
    if (ref) ref();
    this.resultsRefs.delete(athleteID);
    this.resultsByAthlete.get(athleteID).forEach(result => {
      this.results.delete(result.id);
      const byDate = this.resultsByDate.get(result.date);
      byDate.delete(result.id);
      if (byDate.size === 0) this.resultsByDate.delete(result.date);
    });
    this.resultsByAthlete.delete(athleteID);
    this.resultsLoadingForAthlete.delete(athleteID);
  },

  unsubscribeToResultsByAthletes(athleteIDs) {
    athleteIDs.forEach(id => this.unsubscribeToResultsByAthlete(id));
  },

  addResultListener(cb) {
    if (!this.resultListeners.includes(cb)) this.resultListeners.push(cb);
  },

  removeResultListener(cb) {
    var index = this.resultListeners.indexOf(cb);
    if (index > -1) this.resultListeners.splice(index, 1);
  },

  get loading() {
    return Array.from(this.resultsLoadingForAthlete.values()).includes(true);
  },

  areAthleteResultsLoading(athleteID) {
    return (
      !this.resultsLoadingForAthlete.has(athleteID) ||
      this.resultsLoadingForAthlete.get(athleteID) === true
    );
  },

  get allResults() {
    return this.results;
  },

  //XXX needs to incorporate date filter
  get dates() {
    return Array.from(this.resultsByDate.keys()).sort();
  },

  get unfilteredDates() {
    return Array.from(this.resultsByDate.keys()).sort();
  },

  get allResultsGroupedByAthletes() {
    return this.resultsByAthlete;
  },

  get allResultsGroupedByDates() {
    return this.resultsByDate;
  },

  // get unfilteredResultsGroupedByAthletesAndDates() {
  //   const groupedResults = new Map();
  //   this.resultsByAthlete.forEach((athleteResults, athlete) => {
  //     groupedResults.set(
  //       athlete,
  //       Array.from(athleteResults.values()).groupBy('date')
  //     );
  //   });
  //   return groupedResults;
  // },

  getResultsForAthlete(athleteID) {
    return this.getUnfilteredResultsForAthlete(athleteID).filter(result =>
      this.isResultIncluded(result)
    );
  },

  getUnfilteredResultsForAthlete(athleteID) {
    const results = this.resultsByAthlete.get(athleteID);
    if (results) return Array.from(results.values());
    return [];
  },

  getBestResultsForAthlete(athleteID) {
    const bestTimes = new Map();

    this.getUnfilteredResultsForAthlete(athleteID)
      .groupBy('distance')
      .forEach((resultsByDistance, distance) => {
        bestTimes.set(
          distance,
          resultsByDistance.reduce((a, b) => {
            if (b.time < a.time) return b;
            return a;
          })
        );
      });

    return bestTimes;
  },

  getBestResultsForAllAthletes() {
    const athletesBestTimes = new Map();
    Array.from(this.resultsByAthlete.keys()).forEach(id => {
      athletesBestTimes.set(id, this.getBestResultsForAthlete(id));
    });

    return athletesBestTimes;
  },

  //XXX need to consider how sorting is handled (when we let the user specify the sort order)
  //XXX this is probably a bigger question for integrating results display across modules
  getResultsForAthleteAndDate(athleteID, date) {
    const results = this.getResultsForDate(date)
      .filter(
        result => result.athlete === athleteID && this.isResultIncluded(result)
      )
      .sort((a, b) => a.distance - b.distance);
    return results;
  },

  getResultsForAthleteAndDateAndDistance(athleteID, date, distance) {
    const results = this.getResultsForDate(date).filter(
      result =>
        result.athlete === athleteID &&
        result.distance === distance &&
        this.isResultIncluded(result)
    );
    return results;
  },

  // already accounts for filter
  getDatesForAthlete(id) {
    const uniqueDates = new Set();
    this.getResultsForAthlete(id).forEach(r => uniqueDates.add(r.date));
    return uniqueDates;
  },

  getUnfilteredDatesForAthlete(id) {
    const uniqueDates = new Set();
    this.getUnfilteredResultsForAthlete(id).forEach(r =>
      uniqueDates.add(r.date)
    );
    return uniqueDates;
  },

  // already accounts for filter
  getDatesForAthletes(athleteIDs) {
    const uniqueDates = new Set();
    athleteIDs.forEach(id => {
      this.getResultsForAthlete(id).forEach(r => uniqueDates.add(r.date));
    });
    return Array.from(uniqueDates);
  },

  getUnfilteredDatesForAthletes(athleteIDs) {
    const uniqueDates = new Set();
    athleteIDs.forEach(id => {
      this.getUnfilteredResultsForAthlete(id).forEach(r =>
        uniqueDates.add(r.date)
      );
    });
    return Array.from(uniqueDates);
  },

  // already accounts for filter
  getDistancesForAthlete(id) {
    const uniqueDistances = new Set();
    this.getResultsForAthlete(id).forEach(r => uniqueDistances.add(r.distance));
    return Array.from(uniqueDistances);
  },

  getUnfilteredDistancesForAthlete(id) {
    const uniqueDistances = new Set();
    this.getUnfilteredResultsForAthlete(id).forEach(r =>
      uniqueDistances.add(r.distance)
    );
    return Array.from(uniqueDistances);
  },

  getResultsForDate(date, athleteIDs) {
    //XXX handle different date formats

    if (!athleteIDs) athleteIDs = Array.from(this.resultsByAthlete.keys());
    const results = this.resultsByDate.get(date);
    if (results)
      return Array.from(results.values()).filter(
        result =>
          athleteIDs.includes(result.athlete) && this.isResultIncluded(result)
      );
    return [];
  },

  // results for specific athletes grouped by date (sorted: desc),
  // then grouped by distance (sorted: asc)
  getResultsForAthletesGroupedByDateAndDistance(athleteIDs) {
    let athleteResults = [];
    if (!athleteIDs) {
      athleteResults = Array.from(this.allResults.values());
    } else {
      if (!Array.isArray(athleteIDs)) athleteIDs = [athleteIDs];
      athleteIDs.forEach(id => {
        this.getResultsForAthlete(id).forEach(result => {
          athleteResults.push(result);
        });
      });
    }

    const resultsByDate = athleteResults.groupBy('date');

    const dates = Array.from(resultsByDate.keys())
      .sort()
      .reverse();

    const results = new Map();
    dates.forEach(date => {
      const resultsForDistance = new Map();

      const results = resultsByDate.get(date).groupBy('distance');
      Array.from(results.keys())
        .sort((a, b) => a - b)
        .forEach(distance => {
          resultsForDistance.set(distance, results.get(distance));
        });

      results.set(date, resultsForDistance);
    });
    return results;
  },

  getDistancesForDate(date) {
    //XXX handle different date formats
    const uniqueDistances = new Set();
    this.getResultsForDate(date).forEach(r => uniqueDistances.add(r.distance));
    return uniqueDistances;
  },

  saveResults: action(function(results) {
    results.forEach(r => {
      // console.log('ID: ' + r.id + ', time: ' + r.time);

      if (r.id) {
        var resultRef = this.resultsDB.doc(r.id);
        if (r.time) {
          var resultUpdate = {
            athlete: r.athlete,
            date: r.date,
            distance: r.distance,
            time: r.time,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          };
          resultRef.update(resultUpdate);
          //XXX error handling
        } else {
          resultRef.delete();
          //XXX error handling
        }
      } else if (r.time) {
        var details = {
          athlete: r.athlete,
          date: r.date,
          distance: r.distance,
          time: r.time,
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        };
        this.resultsDB
          .add(details)
          .then(function(docRef) {
            console.log('Result created with ID: ', docRef.id);
          })
          .catch(function(error) {
            console.error('Error creating result: ', error);
          });
      }
    });
  }),

  // return true if the result meets filter conditions
  isResultIncluded(result) {
    let distanceFilter = this.filters.get('distances');
    if (distanceFilter) {
      if (!Array.isArray(distanceFilter)) distanceFilter = [distanceFilter];
      if (!distanceFilter.includes(result.distance)) return false;
    }

    return true;
  }
});

export default timeTrialResultsStore;
