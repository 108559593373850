import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Modal, Button, Icon, Container } from 'semantic-ui-react';

class DeleteAthleteModal extends Component {
  ui = observable({ open: false });

  open = () => {
    this.ui.open = true;
  };

  close = (e, { action }) => {
    this.ui.open = false;
    if (action === 'delete') {
      this.props.confirm();
    }
  };

  render() {
    const { athlete } = this.props;

    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button basic icon="trash" />
          )
        }
        open={this.ui.open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick
        closeOnEscape
        closeIcon
        dimmer="blurring"
        centered={true}
      >
        <Modal.Header>Delete athlete</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Container textAlign="center" style={{ marginBottom: '1em' }}>
              <p>
                Are you sure you want to delete {athlete.first_name}{' '}
                {athlete.last_name}?
              </p>
              <p>
                <Icon name="warning sign" color="red" size="huge" />
              </p>
              <p>
                <strong>Warning</strong>:<br />
                This is permanent and all of {athlete.first_name}'s data will be
                lost.
              </p>
              <p>
                You might want to consider archiving {athlete.first_name}'s
                profile instead if they are no longer active. This will keep{' '}
                {athlete.first_name}'s data but not show it by default. Note:
                Archived athletes can be unarchived.
              </p>
            </Container>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close} action="cancel">
            Never mind
          </Button>
          <Button color="red" onClick={this.close} action="delete">
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default observer(DeleteAthleteModal);
