import React from 'react';
import { Component } from 'react';

import { Header } from 'semantic-ui-react';

import Age from './age';

export default class DateHeader extends Component {
  render() {
    const { date, ...otherProps } = this.props;

    return (
      <Header
        size="tiny"
        textAlign="left"
        dividing
        style={{ marginBottom: '0.4em' }}
        {...otherProps}
      >
        <Age date={date} />
        <Header.Subheader
          style={{ display: 'inline-block', marginLeft: '0.5em' }}
        >
          {date.format('Do MMMM Y')}
        </Header.Subheader>
      </Header>
    );
  }
}
