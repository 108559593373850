import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Form, Dropdown } from 'semantic-ui-react';

class ResultsFilterFields extends Component {
  //XXX move to filter store
  filterOptions = {
    distances: [
      { key: '30', text: '30m', value: 30 },
      { key: '60', text: '60m', value: 60 },
      { key: '100', text: '100m', value: 100 },
      { key: '120', text: '120m', value: 120 },
      { key: '150', text: '150m', value: 150 },
      { key: '200', text: '200m', value: 200 },
      { key: '300', text: '300m', value: 300 },
      { key: '400', text: '400m', value: 400 }
    ]
  };

  handleDropdownFilterChange = (e, { type, value }) => {
    const { filters } = this.props.stores;
    filters.set(type, value.length === 0 ? false : value);
  };

  render() {
    const { filters } = this.props.stores;
    const distanceFilter = filters.get('distances');

    return (
      <Form className="results-filter">
        <Form.Field>
          <label>Distances</label>
          <Dropdown
            placeholder="Distances"
            fluid
            multiple
            selection
            type="distances"
            options={this.filterOptions.distances}
            value={
              distanceFilter ? distanceFilter.slice().sort((a, b) => a - b) : []
            }
            onChange={this.handleDropdownFilterChange}
          />
        </Form.Field>
      </Form>
    );
  }
}

export default inject('stores')(observer(ResultsFilterFields));
