import { observable, action } from 'mobx';
import firebase from 'firebase';

var clubsStore = observable({
  stores: null,

  clubsCollection: firebase.firestore().collection('clubs'),
  accountsCollection: firebase.firestore().collection('accounts'),

  closeClubsDBSession: null,
  closeAccountDBSession: null,
  closeRolesDBSession: null,

  userID: null,
  clubs: observable(new Map()),

  activeClub: null,
  account: null,
  roles: new Map(),

  //XXX should perhaps have a loading indicator for account and roles data
  clubsLoading: true,

  init(stores) {
    this.stores = stores;
  },

  subscribe(user) {
    if (this.userID !== null && this.userID !== user.uid) this.unsubscribe();
    this.userID = user.uid;

    if (this.closeClubsDBSession) this.unsubscribe();
    this.closeClubsDBSession = this.clubsCollection
      .where('users', 'array-contains', this.userID)
      .onSnapshot(
        { includeMetadataChanges: true },
        function(snapshot) {
          snapshot.docChanges().forEach(
            function(change) {
              const id = change.doc.ref.id;
              const data = change.doc.data();
              // console.log(change.type + ': ' + id);
              // console.log(data);
              if (change.type === 'added' || change.type === 'modified') {
                data.id = id;
                this.clubs.set(id, data);
                if (!this.activeClub) this.setActiveClub(id);
              } else if (change.type === 'removed') {
                this.clubs.delete(id);
                if (this.activeClub === id) this.setActiveClub(null);
              }
            }.bind(this)
          );
          this.clubsLoading = false;
        }.bind(this),
        function(error) {
          console.error(
            'Error accessing club data for user ' +
              this.userID +
              ': ' +
              error.message
          );
        }.bind(this)
      );
  },

  unsubscribe() {
    this.userID = null;
    this.setActiveClub(null);
    this.clubs.clear();
    if (this.closeClubsDBSession) this.closeClubsDBSession();
    this.clubsLoading = true;
  },

  setActiveClub(id) {
    this.activeClub = id;

    if (id === null) {
      if (this.closeAccountDBSession) this.closeAccountDBSession();
      this.account = null;

      if (this.closeRolesDBSession) this.closeRolesDBSession();
      this.roles.clear();

      this.stores.athletes.unsubscribe();
      this.stores.modules.unsubscribe();
    } else {
      if (!this.stores.modules.isInitialised) {
        this.stores.modules.init(this.stores);
      }
      this.stores.modules.subscribe(id);

      // load account details
      if (this.closeAccountDBSession) this.closeAccountDBSession();
      this.closeAccountDBSession = this.accountsCollection.doc(id).onSnapshot(
        function(doc) {
          this.account = doc.data();

          //XXX take intersection of account.available_modules and club.enabled_modules
          // and subscribe to modules, ie only load module data that is required
        }.bind(this)
      );

      // load roles
      this.closeRolesDBSession = this.clubsCollection
        .doc(id)
        .collection('roles')
        .onSnapshot(
          function(snapshot) {
            snapshot.docChanges().forEach(
              function(change) {
                const id = change.doc.ref.id;
                const data = change.doc.data();
                // console.log(change.type + ': ' + id);
                // console.log(data);
                if (change.type === 'added' || change.type === 'modified') {
                  this.roles.set(id, data);
                } else if (change.type === 'removed') {
                  this.roles.delete(id);
                }
              }.bind(this)
            );
          }.bind(this),
          function(error) {
            console.error(
              'Error accessing roles for club ' + id + ': ' + error.message
            );
          }
        );

      this.stores.athletes.subscribe(id);
    }
  },

  // returns the club actively in use by the user
  // currently just returns the first club found (until multi-club functionality is supported)
  get club() {
    return this.activeClub ? this.clubs.get(this.activeClub) : null;
  },

  get loading() {
    return this.clubsLoading || this.account === null || this.roles.size === 0;
  },

  isModuleEnabled(id) {
    return (
      this.account &&
      this.account.available_modules.includes(id) &&
      this.club &&
      this.club.enabled_modules.includes(id)
    );
  },

  toggleModuleEnabled: action(function(id) {
    if (this.club) {
      if (this.club.enabled_modules.includes(id)) {
        // disable the module in the club settings
        this.clubsCollection
          .doc(this.activeClub)
          .update({
            enabled_modules: firebase.firestore.FieldValue.arrayRemove(id)
          })
          .catch(error => {
            //XXX error handling
          });
      } else if (this.account && this.account.available_modules.includes(id)) {
        // enable the module in the club settings
        this.clubsCollection
          .doc(this.activeClub)
          .update({
            enabled_modules: firebase.firestore.FieldValue.arrayUnion(id)
          })
          .catch(error => {
            //XXX error handling
          });
      }
    }
  }),

  get users() {
    return Array.from(this.roles.keys());
  },

  hasRole(role, id) {
    if (!id) id = this.userID;
    return this.roles.has(id) && this.roles.get(id)[role];
  },

  get isAdmin() {
    if (!this.userID) return null;
    return this.hasRole('admin', this.userID);
  },

  get isCoach() {
    if (!this.userID) return null;
    return this.hasRole('coach', this.userID);
  },

  get squads() {
    if (this.isCoach) {
      const list = this.roles.get(this.userID).coach;
      return Array.isArray(list) ? list : [];
    }
    return null;
  },

  isUserCoach(id) {
    return this.hasRole('coach', id);
  },

  isUserAdmin(id) {
    return this.hasRole('admin', id);
  }
});

export default clubsStore;
