import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { Redirect } from 'react-router-dom';

import {
  Modal,
  Form,
  Button,
  Container,
  Icon,
  Accordion,
  Grid,
  Segment,
  Divider,
  Responsive,
  Label,
  Dropdown
} from 'semantic-ui-react';

import AthleteAvatar from '../../athletes/athlete.avatar';
import DeleteModal from './delete.modal';

class AthleteModal extends Component {
  ui = observable({
    open: false,
    modified: false,
    archiveSectionOpen: false,
    redirect: false
  });

  draft = observable({});

  options = observable({
    gender: [
      { key: 'male', text: 'Male', value: 'male' },
      { key: 'female', text: 'Female', value: 'female' },
      { key: 'none', text: 'Not specified', value: 'notspecified' }
    ],
    tags: []
  });

  open = () => {
    const { athlete } = this.props;

    this.options.tags = this.props.stores.athletes.tags.map(t => {
      return { key: t, text: t, value: t };
    });

    if (athlete) {
      this.draft.id = athlete.id;
      this.draft.club = athlete.club;
      this.draft.first_name = athlete.first_name;
      this.draft.last_name = athlete.last_name;
      this.draft.gender = athlete.gender;
      this.draft.photo_url = athlete.photo_url;
      this.draft.label = '';
      this.draft.tags = athlete.tags ? athlete.tags : [];
    } else {
      this.draft.id = null;
      this.draft.club = this.props.stores.clubs.activeClub;
      this.draft.first_name = '';
      this.draft.last_name = '';
      this.draft.gender = null;
      this.draft.photo_url = null;
      this.draft.label = '';
      this.draft.tags = [];
    }

    this.ui.archiveSectionOpen = athlete && athlete.archived === true;
    this.ui.open = true;
  };

  close = (e, { action }) => {
    if (action === 'save') {
      this.props.stores.athletes.saveAthlete(this.draft);
    }
    this.ui.open = false;
  };

  delete = e => {
    this.ui.open = false;
    // this.ui.redirect = true;
    this.props.stores.athletes.deleteAthlete(this.props.athlete);
  };

  isModified() {
    const { athlete } = this.props;

    if (athlete) {
      return (
        this.draft.id !== athlete.id ||
        this.draft.club !== athlete.club ||
        this.draft.first_name !== athlete.first_name ||
        this.draft.last_name !== athlete.last_name ||
        this.draft.gender !== athlete.gender ||
        this.draft.photo_url !== athlete.photo_url ||
        this.draft.label !== (athlete.label ? athlete.label : '') ||
        !this.draft.tags.equals(athlete.tags ? athlete.tags : [])
      );
    } else {
      return (
        this.draft.id !== null ||
        this.draft.club !== this.props.stores.clubs.activeClub ||
        this.draft.first_name !== '' ||
        this.draft.last_name !== '' ||
        this.draft.gender !== null ||
        this.draft.photo_url !== null ||
        this.draft.label !== '' ||
        this.draft.tags !== []
      );
    }
  }

  handleChange = (e, { name, value }) => {
    this.draft[name] = value;
  };

  toggle = (e, { name }) => {
    if (name === 'archived') {
      if (this.props.athlete.archived) {
        this.ui.archiveSectionOpen = false;
      } else {
        this.ui.open = false;
      }
      // this saves the data if the user has modified the athlete
      // profile. This should perhaps be reviewed
      if (this.isModified()) {
        this.draft.archived = !this.props.athlete.archived;
        this.props.stores.athletes.saveAthlete(this.draft);
      } else {
        this.props.stores.athletes.saveAthlete({
          id: this.props.athlete.id,
          archived: !this.props.athlete.archived
        });
      }
    } else {
      this.draft[name] = !this.draft[name];
    }
  };

  handleClick = e => {
    this.ui.archiveSectionOpen = !this.ui.archiveSectionOpen;
  };

  handleTagAddition = (e, { value }) => {
    this.options.tags = [
      { key: value, text: value, value },
      ...this.options.tags
    ].sort((a, b) => ('' + a.value).localeCompare(b.value));
  };

  handleTagChange = (e, { value }) => (this.draft.tags = value);

  render() {
    const { athlete } = this.props;

    if (this.ui.redirect) {
      return <Redirect to="/settings" />;
    }

    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button basic icon="user plus" />
          )
        }
        open={this.ui.open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick
        closeOnEscape
        closeIcon
        dimmer="blurring"
        centered={false}
      >
        <Modal.Header>{athlete ? 'Edit athlete' : 'Add athlete'}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Container textAlign="center"></Container>
            <Form style={{ fontSize: '1.2rem' }}>
              <Form.Group widths="equal">
                <Form.Field style={{ textAlign: 'center' }}>
                  <AthleteAvatar
                    athlete={this.draft}
                    style={{
                      width: '5em',
                      height: '5em',
                      display: 'block',
                      margin: '0 auto 0.5em'
                    }}
                  />
                  {athlete && athlete.archived && (
                    <Label
                      basic
                      size={this.props.size === 'huge' ? 'large' : 'small'}
                      color="grey"
                      style={{
                        paddingTop: '0.4em',
                        paddingBottom: '0.4em',
                        marginLeft: 0,
                        marginTop: '0.2em'
                      }}
                    >
                      Archived
                    </Label>
                  )}
                </Form.Field>
                <Form.Input
                  fluid
                  label="First name"
                  placeholder="First name"
                  disabled={athlete && athlete.archived ? true : false}
                  name="first_name"
                  value={this.draft.first_name}
                  onChange={this.handleChange}
                  required
                />
                <Form.Input
                  fluid
                  label="Last name"
                  placeholder="Last name"
                  disabled={athlete && athlete.archived ? true : false}
                  name="last_name"
                  value={this.draft.last_name}
                  onChange={this.handleChange}
                  required
                />
                <Form.Select
                  fluid
                  label="Gender"
                  options={this.options.gender}
                  placeholder="Gender"
                  disabled={athlete && athlete.archived ? true : false}
                  name="gender"
                  value={this.draft.gender}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Tags</label>
                  <Dropdown
                    options={this.options.tags}
                    placeholder="Add tags"
                    search
                    selection
                    fluid
                    multiple
                    allowAdditions
                    additionLabel="Add tag: "
                    noResultsMessage="No tags found. Type to create a new one."
                    value={this.draft.tags}
                    onAddItem={this.handleTagAddition}
                    onChange={this.handleTagChange}
                  />
                </Form.Field>
              </Form.Group>
              {athlete && (
                <Accordion>
                  <Accordion.Title
                    active={this.ui.archiveSectionOpen}
                    onClick={this.handleClick}
                  >
                    <Icon name="dropdown" />
                    Archive / Delete
                  </Accordion.Title>
                  <Accordion.Content active={this.ui.archiveSectionOpen}>
                    <Segment>
                      <Grid columns="equal" textAlign="center" stackable>
                        <Grid.Column
                          style={{ marginLeft: '2em', marginRight: '2em' }}
                        >
                          {(athlete.archived && (
                            <Button
                              primary
                              name="archived"
                              onClick={this.toggle}
                            >
                              Unarchive
                            </Button>
                          )) || (
                            <Button
                              color="orange"
                              name="archived"
                              onClick={this.toggle}
                            >
                              Archive
                            </Button>
                          )}

                          <p style={{ marginTop: '1em' }}>
                            {(athlete.archived && (
                              <em>
                                This athlete is currently archived and is hidden
                                from view by default. Unarchiving will make them
                                an active athlete again.
                              </em>
                            )) || (
                              <em>
                                Archiving an athlete will retain their data but
                                will hide it from view. By default, they will
                                not be shown in athlete lists and their data
                                will not be included in analysis.
                              </em>
                            )}
                          </p>
                        </Grid.Column>
                        <Responsive as={Divider} maxWidth={767} horizontal>
                          Or
                        </Responsive>
                        <Responsive as={Divider} minWidth={768} vertical>
                          Or
                        </Responsive>
                        <Grid.Column
                          style={{ marginLeft: '2em', marginRight: '2em' }}
                        >
                          <DeleteModal athlete={athlete} confirm={this.delete}>
                            <Button color="red">Delete</Button>
                          </DeleteModal>
                          <p style={{ marginTop: '1em' }}>
                            <em>
                              This will permanently remove the athlete and their
                              data.
                            </em>
                          </p>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                  </Accordion.Content>
                </Accordion>
              )}
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close} action="cancel">
            {this.isModified() ? 'Cancel' : 'Close'}
          </Button>
          <Button
            primary
            onClick={this.close}
            action="save"
            disabled={!this.isModified()}
          >
            {athlete ? 'Update' : 'Add'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('stores')(observer(AthleteModal));
