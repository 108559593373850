import { observable } from 'mobx';

export default observable({
  filters: new Map([
    // athlete filters
    ['gender', false],
    ['tags', false],
    ['showArchived', false],

    // result filters
    ['distances', false],
    ['dates', false]
  ]),

  ui: { open: false },

  init() {},

  get filterList() {
    return this.filters;
  },

  get(field) {
    return this.filters.get(field);
  },

  set(field, value) {
    return this.filters.set(field, value);
  },

  get active() {
    return Array.from(this.filters.values()).some(f => f !== false);
  }
});
