import React, { Component } from 'react';

import { Label, Icon } from 'semantic-ui-react';

export default class MissingLabel extends Component {
  render() {
    const { count, color } = this.props;

    return (
      <Label circular size="tiny" color={color ? color : null}>
        <Icon name="warning circle" />
        {count} missing result{count > 1 ? 's' : ''}
      </Label>
    );
  }
}
