import React from 'react';
import { Component } from 'react';
import { Header } from 'semantic-ui-react';

class NotFound extends Component {
  render() {
    return (
      <div>
        <Header as="h1" textAlign="center">
          Page Not Found
        </Header>
      </div>
    );
  }
}

export default NotFound;
