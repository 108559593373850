import React from 'react';
import { Component } from 'react';

import AthletesList from './athletes.list';
import AthleteSlider from './athletes.slider';

// wrapper to sit between the Athletes page and the AthleteSlider/AthletesList
// primarily to support redirection from AthleteSlider to
// AthletesList if the requested athlete is not in the list
class AthletesWrapper extends Component {
  athleteChanged = athlete => {
    if (athlete.id !== this.props.requestedID) {
      this.props.history.replace('/athletes/' + athlete.id);
    }
  };

  // checks if the URL specifies an invalid athlete ID
  // ie the filter has changed and the athlete ID is no longer in the current list
  // if it's invalid then redirect to the athlete list
  shouldComponentUpdate = props => {
    if (
      props.requestedID &&
      !props.athletes.map(a => a.id).includes(props.requestedID)
    ) {
      this.props.history.replace('/athletes/');
    }
    return true;
  };

  render() {
    const { athletes, athlete } = this.props;

    if (athlete) {
      return (
        <AthleteSlider
          athletes={athletes}
          athlete={athlete}
          athleteChanged={this.athleteChanged}
        />
      );
    } else {
      return <AthletesList athletes={athletes} />;
    }
  }
}

export default AthletesWrapper;
