import React from 'react';
import { Component } from 'react';

import ReactSwipe from 'react-swipe';

import AthletePanel from './athlete.panel';

class AthletesSlider extends Component {
  handleSwipeChange = index => {
    this.props.athleteChanged(this.props.athletes[index]);
  };

  reactSwipeEl;

  render() {
    const { athletes, athlete } = this.props;

    let startIndex = athletes.findIndex(a => a.id === athlete.id);

    let athleteCards = athletes.map(athlete => {
      return (
        <div key={athlete.id}>
          <AthletePanel athlete={athlete} />
        </div>
      );
    });

    return (
      <div style={{ marginTop: '1.5em' }}>
        <ReactSwipe
          className="carousel"
          swipeOptions={{
            continuous: false,
            startSlide: startIndex,
            transitionEnd: this.handleSwipeChange
          }}
          ref={el => (this.reactSwipeEl = el)}
          childCount={athleteCards.length}
        >
          {athleteCards}
        </ReactSwipe>
      </div>
    );
  }
}

export default AthletesSlider;
