import React, { Component } from 'react';

import { Grid } from 'semantic-ui-react';

import fields from '../fields/fields';

export default class ResultSection extends Component {
  render() {
    const { result, field } = this.props;

    let value = result.data[field];
    const score = result.scores[field];
    if (value !== undefined) {
      switch (fields.fieldType(field)) {
        case 'time':
          value = (value / 1000).toFixed(2);
          break;
        case 'distance':
          value = (value / 10).toFixed(0);
          break;
        case 'category':
          //XXX this should be put somewhere common
          if (field === 'rope-climb-points') {
            if (value === 'legs') value = 'Full climb with legs';
            else if (value === 'incomplete') value = 'Incomplete';
            else value = value + ' climb';
          } else value = value.replace('-', ' ');
          break;
        default:
          break;
      }
    } else {
      value = '-';
    }
    return (
      <Grid.Row key={field}>
        <Grid.Column width={8}>{fields.label(field)}</Grid.Column>
        <Grid.Column width={5} textAlign="center" className="mono">
          {value}
          <br />
          <span style={{ color: 'rgba(0,0,0,0.5)' }}>
            <small>{fields.unitLabel(field)}</small>
          </span>
        </Grid.Column>
        <Grid.Column width={3} textAlign="center">
          {score !== undefined ? score : '-'}
        </Grid.Column>
      </Grid.Row>
    );
  }
}
