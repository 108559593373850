import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import {
  Header,
  Grid,
  Tab,
  Container,
  Message,
  Item,
  Responsive,
  Button,
  Dropdown
} from 'semantic-ui-react';

import AthleteHeader from '../../../athletes/athlete.header';
import ResultSummary from './result.summary';
import ResultSection from './result.section';
import WAGProfileResultRadarChart from '../charts/result.radar.chart';

import fields from '../fields/fields';

import moment from 'moment';

class ResultPanel extends Component {
  ui = observable({
    stacked: false,
    maxComparisons: 1
  });

  toggle = (e, { name }) => {
    this.ui[name] = !this.ui[name];
  };

  changeMaxComparisons = (e, { value }) => {
    this.ui.maxComparisons = value;
  };

  render() {
    const { athlete, result, comparisons } = this.props;

    const numberOfComparisons = comparisons ? comparisons.length : 0;
    const hasComparisons = numberOfComparisons > 0;
    const filteredComparisons =
      comparisons.length > this.ui.maxComparisons
        ? comparisons.slice(0, this.ui.maxComparisons)
        : comparisons;

    const isStacked = this.ui.stacked;

    const ageGroup = result.data['age-group'];
    const overallScore = result.scores['total'];

    const optionsMenu = hasComparisons ? (
      <Dropdown
        icon="cog"
        direction="left"
        style={{
          position: 'absolute',
          right: '1em',
          marginTop: '0.65em',
          zIndex: 1
        }}
      >
        <Dropdown.Menu>
          <Dropdown.Header content="Previous results" />
          <Dropdown.Divider />

          {comparisons
            .slice(0, 3) // allow at most 3 comparison results
            .map((result, index) => (
              <Dropdown.Item
                key={result.id}
                text={
                  'Compare ' + (index + 1) + ' result' + (index > 0 ? 's' : '')
                }
                description={moment(result.date, 'YYYY-MM-DD').format(
                  'Do MMM Y'
                )}
                value={index + 1}
                onClick={this.changeMaxComparisons}
              />
            ))
            .reverse()}

          <Dropdown.Item
            text="Hide previous results"
            value={0}
            onClick={this.changeMaxComparisons}
          />
        </Dropdown.Menu>
      </Dropdown>
    ) : null;

    const expandButton = (
      <Button
        basic={isStacked ? false : true}
        compact
        content={isStacked ? 'Collapse' : 'Enlarge'}
        name="stacked"
        onClick={this.toggle}
        style={{ width: '150px' }}
      ></Button>
    );

    const panes = [
      {
        menuItem: { key: 'data', content: 'Data' },
        render: () => (
          <Tab.Pane as={Container}>
            <Grid>
              {fields.exerciseGroupNames.map(section => (
                <ResultSection
                  result={result}
                  section={section}
                  key={section}
                />
              ))}
            </Grid>
          </Tab.Pane>
        )
      },
      {
        menuItem: { key: 'charts', content: 'Charts' },
        render: () => {
          if (overallScore !== undefined) {
            return (
              <Tab.Pane as={Container}>
                <Responsive minWidth={768}>
                  <Grid columns="equal" textAlign="center">
                    <Grid.Row>
                      <Grid.Column only="computer tablet">
                        {expandButton}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Responsive>

                <Grid stackable columns="equal">
                  <Responsive minWidth={768}>
                    <Grid.Row style={{ height: '2em' }}></Grid.Row>
                  </Responsive>
                  <Grid.Row>
                    <Grid.Column width={isStacked ? 16 : 8}>
                      <div
                        style={{
                          position: 'relative',
                          width: '100%',
                          paddingBottom: '100%'
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%'
                          }}
                        >
                          <WAGProfileResultRadarChart
                            result={result}
                            comparisons={filteredComparisons}
                            items={fields.exerciseGroupNames}
                            showLegend={true}
                            margin={{
                              top: 55
                            }}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={isStacked ? 16 : 8}>
                      <div
                        style={{
                          position: 'relative',
                          width: '100%',
                          paddingBottom: '100%'
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '115%'
                          }}
                        >
                          <WAGProfileResultRadarChart
                            result={result}
                            comparisons={filteredComparisons}
                            items={
                              ageGroup === 'U9'
                                ? fields.U9Fields
                                : fields.U12Fields
                            }
                            rotateLabels={true}
                            margin={{
                              left: 60,
                              right: 60,
                              top: 85,
                              bottom: 85
                            }}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            );
          } else {
            return (
              <Message>
                The data is incomplete and cannot be charted yet
              </Message>
            );
          }
        }
      }
    ];

    return (
      <div>
        {optionsMenu}
        <Grid verticalAlign="middle" style={{ marginTop: '0.65em' }}>
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              <Header size="small">WAG Profile Test Result</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Item.Group unstackable className="athleteItem">
                <AthleteHeader athlete={athlete} style={{ margin: 0 }} />
              </Item.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ResultSummary result={result} comparisons={filteredComparisons} />
        <Tab
          menu={{ secondary: true, pointing: true, widths: 2 }}
          panes={panes}
        />
      </div>
    );
  }
}

export default observer(ResultPanel);
