import React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';

import moment from 'moment';
import 'moment-duration-format';

import { Message, Loader, Table, Item } from 'semantic-ui-react';

import AthleteHeader from './athlete.header';
import DateHeader from '../util/DateHeader';
import ScrollToTopOnMount from '../util/ScrollToTopOnMount.js';

//XXX needs to offload results rendering to modules
//XXX not sure how to deal within interdependant modules, ie calculated-splits rendering differently based on 'inline' setting
//XXX --> could be directly coded into components for now
//XXX need to consider how filtering is implemented in the more general context now
//XXX --> ie modules should be responsible for filtering their own data
class Athlete extends Component {
  constructor(props) {
    super(props);

    this.moduleRenderers = new Map();
    ['time-trial', 'calculated-splits', 'wag-profile-test'].forEach(id =>
      this.moduleRenderers.set(
        id,
        React.lazy(() => import('../modules/' + id + '/results/result.item.js'))
      )
    );
  }

  renderSplit(split) {
    return React.createElement(
      this.moduleRenderers.get('calculated-splits'),
      {
        split: split,
        key: [split.fullResult.id, split.startResult.id, 'split'].join('-')
      },
      null
    );
  }

  render() {
    const { athlete } = this.props;
    const { modules } = this.props.stores;

    if (!athlete)
      return (
        <Message negative>
          <Message.Header>Athlete not found</Message.Header>
        </Message>
      );

    const resultsLoading =
      modules.numberOfEnabledResultsModules > 0
        ? modules.enabledResultsModules.every(
            m => m.areAthleteResultsLoading(athlete.id) === true
          )
        : false;

    //XXX this could be extended to indicate some results are still loading,
    // ie overall spinner for all results loading, and less prominent spiner for some results yet to load
    if (resultsLoading) {
      return (
        <div>
          <Item.Group unstackable link className="athleteItem">
            <AthleteHeader athlete={athlete} size="huge" textAlign="center" />
          </Item.Group>
          <Loader active inline="centered" style={{ marginTop: '2em' }}>
            Loading results
          </Loader>
        </div>
      );
    }

    // get dates from modules
    let dates = new Set();
    modules.enabledResultsModules.forEach(m => {
      const datesFromModule = m.getDatesForAthlete(athlete.id);
      for (const date of datesFromModule.values()) {
        dates.add(date);
      }
    });

    // const bestTimes = results.getBestResultsForAthlete(athlete.id);
    // const filteredResults = filter.resultsByDateAndDistance(athlete.id);

    var rows = [];
    Array.from(dates)
      .sort()
      .reverse()
      .forEach(d => {
        const date = moment(d, 'YYYY-MM-DD');

        // date header
        rows.push(
          <Table.Row key={date}>
            <Table.Cell
              colSpan="3"
              style={{ paddingTop: '2em', paddingBottom: 0, border: 'none' }}
            >
              <DateHeader date={date} />
            </Table.Cell>
          </Table.Row>
        );

        const calculatedSplitsModule = modules.getModule('calculated-splits');
        const calculatedSplitsModuleEnabled = modules.isModuleEnabled(
          'calculated-splits'
        );

        modules.enabledResultsModules.forEach(m => {
          const results = m.getResultsForAthleteAndDate(athlete.id, d);
          if (results && results.length > 0) {
            const splits = [];

            const component = this.moduleRenderers.get(m.id);
            if (component) {
              results.forEach(r => {
                rows.push(
                  React.createElement(component, { result: r, key: r.id }, null)
                );

                if (m.id === 'time-trial' && calculatedSplitsModuleEnabled) {
                  calculatedSplitsModule.getSplitsForResult(r.id).forEach(s => {
                    if (calculatedSplitsModule.settings.inline) {
                      rows.push(this.renderSplit(s));
                    } else {
                      splits.push(s);
                    }
                  });
                }
              });
              splits.forEach(s => rows.push(this.renderSplit(s)));
            }
          }
        });
      });

    return (
      <div>
        <ScrollToTopOnMount />
        <Item.Group unstackable className="athleteItem">
          <AthleteHeader athlete={athlete} size="huge" textAlign="center" />
        </Item.Group>
        {rows.length === 0 && <Message>No results</Message>}
        {rows.length > 0 && (
          <React.Suspense fallback={<Loader />}>
            <Table
              basic
              color="blue"
              unstackable
              textAlign="right"
              style={{ marginTop: 0, border: 'none' }}
            >
              <Table.Body>{rows}</Table.Body>
            </Table>
          </React.Suspense>
        )}
      </div>
    );
  }
}

export default inject('stores')(observer(Athlete));
