import React, { Component } from 'react';
import { ResponsiveRadar } from '@nivo/radar';

import fields from '../fields/fields';

import moment from 'moment';

import colors from './colors';

export default class WAGProfileResultRadarChart extends Component {
  constructor(props) {
    super(props);
    this.label = this.label.bind(this);
    this.rotatedLabel = this.rotatedLabel.bind(this);
  }

  rotatedLabel({ id, anchor, angle }) {
    return this.generateLabel(id, anchor, angle, true);
  }

  label({ id, anchor, angle }) {
    return this.generateLabel(id, anchor, angle, false);
  }

  generateLabel(id, anchor, angle, rotated) {
    let labels = fields.condensedLabel(id);
    if (typeof labels === 'string') labels = [labels];

    if (rotated) {
      anchor = anchor === 'end' ? 'end' : 'start';
    }

    const textElements = labels.map((t, index) => (
      <text
        textAnchor={anchor}
        style={{ fontSize: 11 }}
        y={index > 0 ? index * 12 : null}
        key={index}
      >
        {t}
      </text>
    ));

    return (
      <g
        transform={
          rotated ? `rotate(${anchor === 'end' ? angle + 180 : angle})` : null
        }
        key={id}
      >
        {textElements}
      </g>
    );
  }

  render() {
    const {
      result,
      comparisons,
      items,
      rotateLabels,
      showLegend,
      margin
    } = this.props;

    if (!result || !Array.isArray(items) || items.length < 3) return null;

    const results = [result].concat(comparisons ? comparisons : []);

    const chartColors = colors.chart.slice(0, results.length).reverse();

    const summaryChartData = items.map(field => {
      let details = { area: field };

      results.forEach(result => {
        const formattedDate = moment(result.date, 'YYYY-MM-DD').format(
          'Do MMM Y'
        );
        details[formattedDate] =
          result.scores[field] !== undefined ? result.scores[field] : 0;
      });

      return details;
    });

    const margins = Object.assign(
      { top: 0, right: 55, bottom: 0, left: 55 },
      margin
    );

    return (
      <ResponsiveRadar
        data={summaryChartData}
        keys={results
          .map(r => moment(r.date, 'YYYY-MM-DD').format('Do MMM Y'))
          .reverse()}
        indexBy="area"
        maxValue={10}
        colors={chartColors}
        fillOpacity={0}
        margin={margins}
        gridShape="linear"
        gridLevels={10}
        gridLabelOffset={12}
        gridLabel={rotateLabels ? this.rotatedLabel : this.label}
        dotSize={10}
        tooltipFormat={(value, key) => value.toFixed(1)}
        animate={false}
        legends={
          showLegend
            ? [
                {
                  anchor: 'top-left',
                  direction: 'column',
                  translateX: -50,
                  translateY: 0,
                  itemWidth: 120,
                  itemHeight: 20,
                  itemTextColor: '#999',
                  symbolSize: 12,
                  symbolShape: 'circle'
                }
              ]
            : []
        }
      />
    );
  }
}
