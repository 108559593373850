import React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';

import Panel from '../components/modules/wag-profile-test/results/result.panel';
import moment from 'moment';

class ResultPage extends Component {
  moduleList = ['wag-profile-test'];

  // constructor(props) {
  //   super(props);

  //   this.moduleRenderers = new Map();
  //   this.moduleList.forEach(id =>
  //     this.moduleRenderers.set(
  //       id,
  //       React.lazy(() =>
  //         import('../components/modules/' + id + '/results/result.panel.js')
  //       )
  //     )
  //   );
  // }

  render() {
    const { moduleID, resultID } = this.props.match.params;

    if (!this.moduleList.includes(moduleID)) {
      //XXX should probably return 404
      return null;
    }

    // retrieve module store
    const module = this.props.stores.modules.getModule(moduleID);
    if (!module) {
      //XXX generate an error page
      return null;
    }

    // get result from module store
    const result = module.results.get(resultID);
    if (!result) {
      //XXX return 404 (but intermediate renders may be a problem when result not found yet)
      return null;
    }

    // render result using module result panel
    const athlete = this.props.stores.athletes.athletesMap.get(result.athlete);

    // collection of the athlete's comparable results (ie same age-group)
    // that are dated prior to the result, in date order
    // note: this collection ignores any filter
    const date = moment(result.date, 'YYYY-MM-DD');

    const pastResults = module
      .getUnfilteredResultsForAthlete(result.athlete)
      .filter(
        r =>
          r.data['age-group'] === result.data['age-group'] &&
          moment(r.date, 'YYYY-MM-DD').isBefore(date, 'day')
      )
      .sort((a, b) => {
        return moment(b.date, 'YYYY-MM-DD').isBefore(
          moment(a.date, 'YYYY-MM-DD')
        )
          ? -1
          : 1;
      });

    //XXX
    // const component = this.moduleRenderers.get(moduleID);

    return (
      <Panel athlete={athlete} result={result} comparisons={pastResults} />
    );
  }
}

export default inject('stores')(observer(ResultPage));
