import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';

import stores from './stores';
import { Provider, observer } from 'mobx-react';

import 'semantic-ui-css/semantic.min.css';
import './App.css';
import { Container, Dimmer, Loader } from 'semantic-ui-react';

import './util/array.js';

import Login from './pages/login.page';
import MainMenu from './components/menu/main.menu';
import Squad from './pages/squad.page';
import Athletes from './pages/athletes.page';
import Athlete from './pages/athlete.page';
import Result from './pages/result.page';
import Settings from './pages/settings.page';
import DataEntry from './pages/data.entry.page';
import WaitlistedUserPanel from './components/users/waitlisted.user.panel';
import NotFound from './pages/404.page';

class App extends Component {
  render() {
    const { auth, settings, clubs, athletes } = stores;

    let appClassName = 'App';
    if (!auth.isSignedIn) appClassName += ' login';
    if (!clubs.isCoach && !clubs.isAdmin) appClassName += ' waitlisted';
    const athleteCount = athletes.allAthletes.length;

    let content = null;

    if (auth.initialising) {
      content = (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      );
    } else if (!auth.isSignedIn) {
      content = (
        <Switch>
          <Route exact path="/" component={Login} />
          <Redirect exact from="/**" to="/" />
        </Switch>
      );
    } else if (!auth.ready) {
      content = (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      );
    } else if (settings.loading) {
      appClassName += ' loading';
      content = (
        <Dimmer active inverted>
          <Loader inverted>Loading app settings</Loader>
        </Dimmer>
      );
    } else if (clubs.loading) {
      appClassName += ' loading';
      content = (
        <Dimmer active inverted>
          <Loader inverted>Loading club details</Loader>
        </Dimmer>
      );
    } else if (athletes.loading) {
      appClassName += ' loading';
      content = (
        <Dimmer active inverted>
          <Loader inverted>Loading athlete list</Loader>
        </Dimmer>
      );
    } else if ((clubs.isCoach || clubs.isAdmin) && athleteCount === 0) {
      content = (
        <Switch>
          <Route
            exact
            path="/settings"
            render={props => <Settings {...props} section="athletes" />}
          />
          <Redirect exact from="/**" to="/settings" />
        </Switch>
      );
    } else if (clubs.isCoach || clubs.isAdmin) {
      content = (
        <Switch>
          <Redirect exact from="/" to="/athletes" />
          <Route exact path="/results" component={Squad} />
          <Route path="/results/:moduleID/:resultID" component={Result} />
          <Route path="/athletes/:id?" component={Athletes} />
          <Route path="/athlete/:id" component={Athlete} />

          <Route path="/data/:module?" component={DataEntry} />
          <Route exact path="/settings" component={Settings} />
          <Route component={NotFound} />
        </Switch>
      );
    } else {
      content = <WaitlistedUserPanel auth={auth} />;
    }

    return (
      <Provider stores={stores}>
        <div className={appClassName}>
          <MainMenu location={this.props.location} />
          <Container text className="main">
            {content}
          </Container>
        </div>
      </Provider>
    );
  }
}

export default withRouter(observer(App));
