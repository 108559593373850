import React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { Message, Header, Tab } from 'semantic-ui-react';

import AccountSettingsPanel from '../components/settings/account/account.settings.panel';
import AthletesSettingsPanel from '../components/settings/athletes/athletes.settings.panel';
import TagsSettingsPanel from '../components/settings/tags/tags.settings.panel';

class Settings extends Component {
  ui = observable({ activeIndex: 0 });

  handleTabChange = (e, { activeIndex }) => (this.ui.activeIndex = activeIndex);

  render() {
    const { clubs, athletes } = this.props.stores;
    // const { profile } = auth;

    // if (!profile) return <Message header="Error loading profile" />;

    const panes = [
      {
        menuItem: { key: 'account', content: 'Account' },
        render: () => (
          <Tab.Pane as="div">
            <AccountSettingsPanel />
          </Tab.Pane>
        )
      },
      clubs.isCoach || clubs.isAdmin
        ? {
            menuItem: {
              key: 'athletes',
              content: 'Athletes'
            },
            render: () => (
              <Tab.Pane as="div">
                <AthletesSettingsPanel />
              </Tab.Pane>
            )
          }
        : null,
      clubs.isCoach || clubs.isAdmin
        ? {
            menuItem: {
              key: 'tags',
              content: 'Tags'
            },
            render: () => (
              <Tab.Pane as="div">
                <TagsSettingsPanel />
              </Tab.Pane>
            )
          }
        : null
    ];

    return (
      <div>
        <Header size="large" textAlign="center" style={{ marginTop: '0.4em' }}>
          Settings
          <Header.Subheader>Manage your preferences</Header.Subheader>
        </Header>

        {(clubs.isCoach || clubs.isAdmin) && athletes.size === 0 && (
          <Message info>
            <Message.Header>Your athlete list is empty</Message.Header>
            <p>
              Once you have created your athlete list below you can start
              managing results using the rest of the app.
            </p>
          </Message>
        )}

        <Tab
          menu={{ secondary: true, pointing: true }}
          activeIndex={this.ui.activeIndex}
          onTabChange={this.handleTabChange}
          panes={panes}
        />
      </div>
    );
  }
}

export default inject('stores')(observer(Settings));
