import React from 'react';
import { Component } from 'react';

import { Message, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default class ModulesWarning extends Component {
  render() {
    const { message, ...otherProps } = this.props;

    return (
      <Message icon warning {...otherProps}>
        <Icon name="warning sign" />
        <Message.Content>
          <p>
            {message
              ? message
              : "There are no results modules enabled so we can't show you any results here"}
          </p>
          <p>
            Head to the <Link to="/settings">settings page</Link> to get started
          </p>
        </Message.Content>
      </Message>
    );
  }
}
