import React, { Component } from 'react';

import { Header, Grid } from 'semantic-ui-react';

import ExerciseItem from './exercise.item';

import fields from '../fields/fields';

export default class ResultSection extends Component {
  generateSection(result, section) {
    let rows = [];

    // add section header
    const score = result.scores[section];
    const title = fields.label(section);
    rows.push(
      <Grid.Row key={section} style={{ paddingTop: '3rem' }}>
        <Grid.Column width={8}>
          <Header size="small">{title}</Header>
        </Grid.Column>
        <Grid.Column width={5} textAlign="center"></Grid.Column>
        <Grid.Column width={3} textAlign="center">
          <Header size="medium">
            {score !== undefined ? score.toFixed(1) : '-'}
          </Header>
        </Grid.Column>
      </Grid.Row>
    );

    // add section exercise results
    fields.exerciseGroups[section].forEach(field => {
      if (
        (result.data['age-group'] === 'U9' &&
          fields.U9Fields.includes(field)) ||
        (result.data['age-group'] === '9-12yrs' &&
          fields.U12Fields.includes(field))
      ) {
        rows.push(<ExerciseItem result={result} field={field} key={field} />);
      }
    });

    return rows;
  }

  render() {
    const { result, section } = this.props;

    return (
      <Grid verticalAlign="middle" style={{ marginTop: '0.4em' }}>
        {this.generateSection(result, section)}
      </Grid>
    );
  }
}
