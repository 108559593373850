import React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import { Item } from 'semantic-ui-react';

import AthleteHeader from './athlete.header';

class AthletesList extends Component {
  rndm(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
  render() {
    const { athletes } = this.props;
    var athleteRows = [];
    athletes.forEach(athlete => {
      athleteRows.push(
        <AthleteHeader athlete={athlete} key={athlete.id} link />
      );
    });
    return (
      <div>
        <Item.Group unstackable link className="athleteList">
          {athleteRows}
        </Item.Group>
      </div>
    );
  }
}

export default observer(AthletesList);
