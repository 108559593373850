import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Form, Checkbox } from 'semantic-ui-react';

class AthleteFilterFields extends Component {
  //XXX move to filter store
  options = {
    gender: [
      { key: 'male', text: 'Male', value: 'male' },
      { key: 'female', text: 'Female', value: 'female' },
      { key: 'none', text: 'Not specified', value: 'notspecified' }
    ],
    tags: []
  };

  handleDropdownFilterChange = (e, { type, value }) => {
    const { filters } = this.props.stores;
    if (value.length === 0) {
      filters.set(type, false);
    } else {
      filters.set(type, value);
    }
  };

  toggleFilter = (e, { name }) => {
    const { filters } = this.props.stores;
    const current = filters.get(name);
    filters.set(name, !current);
  };

  render() {
    const { filters } = this.props.stores;
    const showArchived = filters.get('showArchived');

    this.options.tags = this.props.stores.athletes.tags.map(t => {
      return { key: t, text: t, value: t };
    });

    const filterGender = filters.get('gender') ? filters.get('gender') : [];
    const filterTags = filters.get('tags') ? filters.get('tags') : [];

    return (
      <Form className="athlete-filter">
        <Form.Field>
          <label>Tags</label>
          <Form.Dropdown
            multiple
            search
            selection
            clearable
            type="tags"
            options={this.options.tags}
            value={filterTags}
            onChange={this.handleDropdownFilterChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Gender</label>
          <Form.Dropdown
            multiple
            selection
            clearable
            type="gender"
            options={this.options.gender}
            value={filterGender}
            onChange={this.handleDropdownFilterChange}
          />
        </Form.Field>

        <Form.Field>
          <Checkbox
            label="Show archived athletes"
            name="showArchived"
            onChange={this.toggleFilter}
            checked={showArchived}
          />
        </Form.Field>
      </Form>
    );
  }
}

export default inject('stores')(observer(AthleteFilterFields));
