import React from 'react';
import { Component } from 'react';

import moment from 'moment';
import 'moment-duration-format';

export default class Age extends Component {
  capitaliseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const { date, ...otherProps } = this.props;

    const today = moment().startOf('day');
    const age =
      date.diff(today, 'days') === 0
        ? 'Today'
        : date.diff(today, 'days') === -1
        ? 'Yesterday'
        : date.from(today);

    return <span {...otherProps}>{this.capitaliseFirstLetter(age)}</span>;
  }
}
