import React, { Component } from 'react';

export default class AthleteName extends Component {
  render() {
    const { athlete } = this.props;

    const name =
      (athlete.first_name ? athlete.first_name : '') +
      ' ' +
      (athlete.last_name ? athlete.last_name : '');

    return <span>{name}</span>;
  }
}
