import React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Loader, Header, Message } from 'semantic-ui-react';

import FilterPanel from '../components/filter/filter.panel';
import AthletesWrapper from '../components/athletes/athletes.wrapper';
import ModulesWarning from '../components/modules/modules.warning';

class Athletes extends Component {
  render() {
    const { modules } = this.props.stores;
    const { loading, athletes } = this.props.stores.athletes;

    if (loading || athletes === null) {
      return <Loader active>Loading athletes</Loader>;
    }

    const { id } = this.props.match.params;
    const athlete = athletes.find(athlete => athlete.id === id);

    return (
      <div>
        <FilterPanel hideResultsFilter={id === undefined || id === null} />

        {!athlete && (
          <Header
            size="large"
            textAlign="center"
            style={{ marginTop: '0.4em' }}
          >
            Athletes
          </Header>
        )}

        {modules.numberOfEnabledResultsModules === 0 && (
          <ModulesWarning style={athlete ? { marginTop: '2rem' } : {}} />
        )}

        {athletes.length === 0 ? (
          <Message>No athletes</Message>
        ) : (
          <AthletesWrapper
            athletes={athletes}
            athlete={athlete}
            requestedID={id}
            history={this.props.history}
          />
        )}
      </div>
    );
  }
}

export default inject('stores')(observer(Athletes));
