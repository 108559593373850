import { observable, action } from 'mobx';
import firebase from 'firebase';

//XXX how is this incorporated into filters? Hardwire into filters.store for now?

var calculatedSplitsModuleStore = observable({
  id: null,
  name: null,
  description: null,
  capabilities: null,
  settings: null,

  settingsRef: null,
  closeDBSession: null,

  modules: null,
  resultsStore: null,

  // key: result ID (for full split distance)
  // Value: (array) splits
  splits: new Map(),

  calculating: false,

  init(data, { modules, athletes, filters }) {
    this.resultChanged = this.resultChanged.bind(this);

    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.capabilities = data.capabilities;

    this.modules = modules;

    this.setResultsStore(this.modules.getModule('time-trial'));
    if (!this.resultsStore) {
      this.modules.addModuleListener(this.moduleListener.bind(this));
    }
  },

  moduleListener(id, module) {
    if (id === 'time-trial') {
      this.setResultsStore(module);
      this.modules.removeModuleListener(this.moduleListener);
    }
  },

  setResultsStore(store) {
    if (store) {
      this.resultsStore = store;
      this.resultsStore.addResultListener(this.resultChanged);
      this.calculateSplits();
    }
  },

  resultChanged(change, result) {
    if (change === 'added' || change === 'modified') {
      // get results for athlete + date then calculate splits relevant for result distance, and store
      this.calculateSplitsFromResult(result);
    } else if (change === 'removed') {
      this.removeSplitsFromResult(result);
    }
  },

  subscribe(clubID) {
    this.settingsRef = firebase
      .firestore()
      .collection('modules')
      .doc(this.id)
      .collection('settings')
      .doc(clubID);

    this.settingsRef
      .get()
      .then(
        function(doc) {
          if (doc.exists) {
            this.settings = doc.data();
            this.calculateSplits();
          } else {
            // create settings doc given it doesn't already exist
            this.settingsRef
              .set({
                inline: true,
                distances: [],
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
              })
              .then(() => {
                console.log('Settings entry created for ' + this.name);
              })
              .catch(error => {
                //XXX error handling
                console.error('Error creating module settings: ', error);
              });
          }
        }.bind(this)
      )
      .catch(function(error) {
        //XXX error handling
        console.log('Error getting document:', error);
      });

    this.closeDBSession = this.settingsRef.onSnapshot(
      function(doc) {
        this.settings = doc.data();
        this.calculateSplits();
      }.bind(this),
      function(error) {
        console.error(
          'Error accessing calculated-splits module settings: ' + error.message
        ); //XXX
      }
    );
  },

  unsubscribe() {
    if (this.closeDBSession) {
      this.closeDBSession();
    }
    this.settingsRef = null;
    this.settings = null;
  },

  toggleModuleSetting: action(function(field) {
    if (this.settingsRef) {
      let details = {
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      details[field] = this.settings ? !this.settings[field] : true;
      this.settingsRef
        .update(details)
        .then(() => {
          // console.log(
          //   'Module "' +
          //     this.name +
          //     '" setting "' +
          //     field +
          //     '" ' +
          //     (details[field] ? 'enabled' : 'disabled')
          // );
        })
        .catch(error => {
          //XXX error handling
          console.error('Error updating module settings: ', error);
        });
    }
  }),

  get resultsCount() {
    if (this.resultsStore) return this.resultsStore.results.size;
    return undefined;
  },

  getSplitsForResult(id) {
    const splits = this.splits.get(id);
    return splits
      ? splits.slice().sort((a, b) => a.startDistance - b.startDistance)
      : [];
  },

  createSplit: action(function(full, start) {
    if (this.settingsRef) {
      this.settingsRef
        .update({
          distances: firebase.firestore.FieldValue.arrayUnion({
            full: full,
            start: start
          }),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
          // console.log(full + 'm - ' + start + 'm split added');
        })
        .catch(error => {
          //XXX error handling
          console.error(
            'Error adding ' + full + 'm - ' + start + 'm split: ',
            error
          );
        });
    }
  }),

  deleteSplit: action(function(full, start) {
    if (this.settingsRef) {
      this.settingsRef
        .update({
          distances: firebase.firestore.FieldValue.arrayRemove({
            full: full,
            start: start
          }),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
          // console.log(full + 'm - ' + start + 'm split deleted');
        })
        .catch(error => {
          //XXX error handling
          console.error(
            'Error deleting ' + full + 'm - ' + start + 'm split: ',
            error
          );
        });
    }
  }),

  //XXX this could be more efficient it knew what settings have changed
  calculateSplits() {
    if (this.resultsStore) {
      this.calculating = true;
      this.splits = new Map();
      this.resultsStore.allResults.forEach(result =>
        this.calculateSplitsFromResult(result)
      );
      this.calculating = false;
    }
  },

  calculateSplitsFromResult(result) {
    if (this.resultsStore) {
      const otherResults = this.resultsStore
        .getResultsForAthleteAndDate(result.athlete, result.date)
        .filter(r => r.id !== result.id);

      if (otherResults.length > 0 && this.settings) {
        // find the calculated-split pairs that have a 'full' distance the same as this result
        this.settings.distances
          .filter(splitCfg => splitCfg.full === result.distance)
          .forEach(splitCfg => {
            // find others results in the athlete/date results that have the 'start' distance
            otherResults
              .filter(otherResult => otherResult.distance === splitCfg.start)
              .forEach(otherResult => {
                // calculate and store the calculated split result
                const splitResult = {
                  date: result.date,
                  athleteID: result.athlete,
                  startDistance: splitCfg.start,
                  startResult: otherResult,
                  fullDistance: splitCfg.full,
                  fullResult: result,
                  time: result.time - otherResult.time
                };

                if (!this.splits.has(result.id)) {
                  this.splits.set(result.id, []);
                }

                const splits = this.splits.get(result.id);
                const index = splits.findIndex(
                  s => s.startResult.id === otherResult.id
                );
                if (index > -1) {
                  splits.splice(index, 1, splitResult);
                } else {
                  splits.push(splitResult);
                }
              });
          });

        // find the calculated-split pairs that have a 'start' distance the same as this result
        this.settings.distances
          .filter(splitCfg => splitCfg.start === result.distance)
          .forEach(splitCfg => {
            // find others results in the athlete/date results that have the 'start' distance
            otherResults
              .filter(otherResult => otherResult.distance === splitCfg.full)
              .forEach(otherResult => {
                // calculate and store the calculated split result
                const splitResult = {
                  date: otherResult.date,
                  athleteID: otherResult.athlete,
                  startDistance: splitCfg.start,
                  startResult: result,
                  fullDistance: splitCfg.full,
                  fullResult: otherResult,
                  time: otherResult.time - result.time
                };

                if (!this.splits.has(otherResult.id)) {
                  this.splits.set(otherResult.id, []);
                }

                const splits = this.splits.get(otherResult.id);
                const index = splits.findIndex(
                  s => s.startResult.id === result.id
                );
                if (index > -1) {
                  splits.splice(index, 1, splitResult);
                } else {
                  splits.push(splitResult);
                }
              });
          });
      }
    }
  },

  removeSplitsFromResult(result) {
    // find splits derived from this result (as 'full' distance) and remove them
    this.splits.delete(result.id);

    // find splits that use this result as the 'start' result and remove them
    for (const splitList of this.splits.values()) {
      let index = splitList.findIndex(s => s.startDistance === result.distance);
      while (index !== -1) {
        console.log(splitList[index]);
        splitList.splice(index, 1);
        index = splitList.findIndex(s => s.startDistance === result.distance);
      }
    }
  }
});

export default calculatedSplitsModuleStore;
