import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, Tab, Container, Button, Icon } from 'semantic-ui-react';

import AthletesFilter from './athlete.filter.fields';
import ResultsFilter from './results.filter.fields';

class FilterPanel extends Component {
  clearFilters = e => {
    e.stopPropagation();
    const { filters } = this.props.stores;
    filters.filterList.forEach((value, name) => filters.set(name, false));
    filters.ui.open = false;
  };

  toggleFilters = (e, titleProps) => {
    const { filters } = this.props.stores;
    filters.ui.open = !filters.ui.open;
  };

  render() {
    const { filters } = this.props.stores;

    if (!filters.ui.open) return null;

    const { hideResultsFilter } = this.props;

    return (
      <Grid
        style={{
          borderBottom: '1px solid rgba(34,36,38,.15)',
          boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
          backgroundColor: '#fffbf3',
          paddingTop: '1.5em',
          marginBottom: '0.5em'
        }}
        className="filter"
      >
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width={10}></Grid.Column>
          <Grid.Column width={6} textAlign="right" style={{ zIndex: 1 }}>
            <Button
              basic
              size="tiny"
              compact
              color="orange"
              onClick={this.clearFilters}
              style={{
                marginRight: '1.25em',
                visibility: filters.active ? 'visible' : 'hidden'
              }}
            >
              Reset
            </Button>
            <Icon
              name="triangle up"
              style={{ fontSize: '1.2em', cursor: 'pointer' }}
              onClick={this.toggleFilters}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0, marginTop: '-1.6em' }}>
          <Grid.Column width={16}>
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: {
                    key: 'athletes',
                    content: 'Athletes'
                  },
                  render: () => (
                    <Tab.Pane
                      as={Container}
                      style={{ border: 'none', boxShadow: 'none' }}
                    >
                      <AthletesFilter />
                    </Tab.Pane>
                  )
                },
                hideResultsFilter
                  ? null
                  : {
                      menuItem: {
                        key: 'results',
                        content: 'Results'
                      },
                      render: () => (
                        <Tab.Pane
                          as={Container}
                          style={{ border: 'none', boxShadow: 'none' }}
                        >
                          <ResultsFilter />
                        </Tab.Pane>
                      )
                    }
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default inject('stores')(observer(FilterPanel));
