import React, { Component } from 'react';

import { Grid, Divider } from 'semantic-ui-react';

import MissingLabel from './missing.label';
import Improvement from '../../../util/improvement';
import WAGProfileTargetChart from '../charts/targets.bullet.chart';

import moment from 'moment';

export default class ResultSummary extends Component {
  render() {
    const { result, comparisons } = this.props;

    const hasComparisons = comparisons && comparisons.length > 0;
    const formattedResultDate = moment(result.date, 'YYYY-MM-DD').format(
      'Do MMM Y'
    );

    const overallScore = result.scores['total'];
    const overallPercent = result.scores['total-percent'];
    const overallMissingScores = result.scores['numberMissing'];
    const comparisonScore = hasComparisons
      ? comparisons[0].scores['total']
      : null;

    const comparisonRows = hasComparisons
      ? comparisons
          .slice()
          .reverse()
          .map(comparison => {
            const formattedComparisonDate = moment(
              comparison.date,
              'YYYY-MM-DD'
            ).format('Do MMM Y');
            const comparisonScore = comparison
              ? comparison.scores['total']
              : null;
            const comparisonPercent = comparison
              ? comparison.scores['total-percent']
              : null;
            const comparisonMissingScores = comparison.scores['numberMissing'];

            let rows = [
              <Grid.Row
                style={{
                  paddingTop: 0,
                  paddingBottom: comparisonMissingScores > 0 ? 0 : null,
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontSize: '1.1rem'
                }}
                key={comparison.id}
              >
                <Grid.Column width={7} textAlign="center">
                  {formattedComparisonDate}
                </Grid.Column>
                <Grid.Column width={1} textAlign="center" />
                <Grid.Column width={3} textAlign="center">
                  <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                    {comparisonScore}
                  </span>
                </Grid.Column>
                <Grid.Column width={4} textAlign="center">
                  <span>{(comparisonPercent * 100).toFixed(1)}%</span>
                </Grid.Column>
              </Grid.Row>
            ];

            if (comparisonMissingScores > 0) {
              rows.push(
                <Grid.Row
                  style={{ paddingTop: 0 }}
                  key={comparison.id + '-missing'}
                >
                  <Grid.Column width={7} textAlign="center">
                    <MissingLabel count={comparisonMissingScores} />
                  </Grid.Column>
                </Grid.Row>
              );
            }

            return rows;
          })
          .flat()
      : null;

    return (
      <Grid
        verticalAlign="middle"
        style={{ marginTop: '1.4em', marginBottom: '0.4em' }}
      >
        {comparisonRows}
        {hasComparisons && <Divider style={{ marginTop: 0 }} />}
        <Grid.Row
          style={{ paddingTop: '0rem', paddingBottom: 0 }}
          key={result.id}
        >
          <Grid.Column
            width={7}
            textAlign="center"
            style={{ fontWeight: 'bold' }}
          >
            {formattedResultDate}
          </Grid.Column>
          <Grid.Column width={1} textAlign="center" />
          <Grid.Column width={3} textAlign="center">
            <span style={{ fontSize: '1.3rem', color: 'rgba(0, 0, 0, 0.5)' }}>
              {overallScore !== undefined ? overallScore : '-'}
            </span>
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              {overallPercent !== undefined
                ? (overallPercent * 100).toFixed(1) + '%'
                : '-'}
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid.Column width={7} textAlign="center">
            {overallMissingScores > 0 && (
              <MissingLabel count={overallMissingScores} color="orange" />
            )}
          </Grid.Column>
          <Grid.Column width={5} textAlign="center">
            <Improvement value={overallScore} comparison={comparisonScore} />
          </Grid.Column>
        </Grid.Row>
        {overallPercent !== undefined && (
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              <WAGProfileTargetChart
                result={result}
                comparisons={comparisons}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}
