import { observable, action } from 'mobx';
import firebase from 'firebase';
import moment from 'moment';

var checkinsStore = observable({
  id: null,
  name: null,
  description: null,
  capabilities: null,

  settings: null,
  settingsRef: null,
  closeSettingsDBSession: null,

  visitorsDB: firebase
    .firestore()
    .collection('modules')
    .doc('checkins')
    .collection('visitors'),
  closeVisitorsDBSession: null,

  visitorsMap: new Map(),

  visitsDB: firebase
    .firestore()
    .collection('modules')
    .doc('checkins')
    .collection('visits'),
  closeVisitsDBSession: null,

  visitsMap: new Map(),
  visitsByDate: new Map(),

  dataLoading: { visitors: true, visits: true },

  init(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.capabilities = data.capabilities;
  },

  subscribe(clubID) {
    this.settingsRef = firebase
      .firestore()
      .collection('modules')
      .doc(this.id)
      .collection('settings')
      .doc(clubID);

    this.closeSettingsDBSession = this.settingsRef.onSnapshot(
      function(doc) {
        this.settings = doc.data();
      }.bind(this),
      function(error) {
        console.error(
          'Error accessing checkins settings data: ' + error.message
        );
      }
    );

    this.visitorsMap.clear();

    this.closeVisitorsDBSession = this.visitorsDB
      .where('club', '==', clubID)
      .onSnapshot(
        { includeMetadataChanges: true },
        function(snapshot) {
          snapshot.docChanges().forEach(
            function(change) {
              const id = change.doc.ref.id;
              const data = change.doc.data();
              // console.log(change.type + ': ' + id);
              // console.log(data);

              if (change.type === 'added' || change.type === 'modified') {
                data.id = id;
                data.isVisitor = true;
                data.hasPendingWrites = change.doc.metadata.hasPendingWrites;
                this.visitorsMap.set(id, data); // complete list of visitors for club
              } else if (change.type === 'removed') {
                this.visitorsMap.delete(id);
              }
            }.bind(this)
          );
          this.dataLoading.visitors = false;
        }.bind(this),
        function(error) {
          console.error(
            'Error accessing checkin visitor data for club: ' + error.message
          );
        }
      );

    this.visitorsMap.clear();
    this.visitsByDate.clear();

    this.closeVisitsDBSession = this.visitsDB
      .where('club', '==', clubID)
      .onSnapshot(
        { includeMetadataChanges: true },
        function(snapshot) {
          snapshot.docChanges().forEach(
            function(change) {
              const id = change.doc.ref.id;
              const data = change.doc.data();
              // console.log(change.type + ': ' + id);
              // console.log(data);

              const checkinDate = moment(data.time_in.toDate()).format(
                'YYYY-MM-DD'
              );
              if (!this.visitsByDate.has(checkinDate)) {
                this.visitsByDate.set(checkinDate, new Map());
              }
              var date = this.visitsByDate.get(checkinDate);

              if (change.type === 'added' || change.type === 'modified') {
                data.id = id;
                if (data.time_in) {
                  data.time_in = moment(data.time_in.toDate());
                }
                if (data.time_out) {
                  data.time_out = moment(data.time_out.toDate());
                }
                data.hasPendingWrites = change.doc.metadata.hasPendingWrites;
                this.visitsMap.set(id, data); // complete list of visits for club
                date.set(id, data); // visits for the specific date (across all visitors)
              } else if (change.type === 'removed') {
                this.visitsMap.delete(id);
                date.delete(id);
              }
            }.bind(this)
          );
          this.dataLoading.visits = false;
        }.bind(this),
        function(error) {
          console.error(
            'Error accessing checkin visits data for club: ' + error.message
          );
        }
      );
  },

  unsubscribe() {
    if (this.closeSettingsDBSession) {
      this.closeSettingsDBSession();
    }
    this.settingsRef = null;
    this.settings = null;

    if (this.closeVisitsDBSession) {
      this.closeVisitsDBSession();
    }
    this.visitsMap.clear();
    this.visitsByDate.clear();

    if (this.closeVisitorsDBSession) {
      this.closeVisitorsDBSession();
    }
    this.visitorsMap.clear();

    this.dataLoading.visitors = true;
    this.dataLoading.visits = true;
  },

  get loading() {
    return this.dataLoading.visitors || this.dataLoading.visits;
  },

  get visitors() {
    return Array.from(this.visitorsMap.values());
  },

  get numberOfVisitors() {
    return this.visitorsMap.size;
  },

  //XXX need to also users
  getVisitsForDateByAthlete(date) {
    const visitsForDate = this.visitsByDate.get(date);
    if (visitsForDate) {
      //XXX this should sort visits by time_in here
      return Array.from(visitsForDate.values()).groupBy('athlete');
    }
    return new Map();
  },

  getVisitsForDateByVisitor(date) {
    const visitsForDate = this.visitsByDate.get(date);
    if (visitsForDate) {
      //XXX this should sort visits by time_in here
      return Array.from(visitsForDate.values()).groupBy('visitor');
    }
    return new Map();
  },

  //XXX this should do some proper data validation
  areVisitorDetailsValid(details) {
    const valid =
      details.club !== null &&
      details.club !== undefined &&
      details.first_name !== undefined &&
      details.first_name.length > 0 &&
      details.last_name !== undefined &&
      details.last_name.length > 0 &&
      details.email !== undefined &&
      details.email.length > 0 &&
      details.phone !== undefined &&
      details.phone.length > 0;
    return valid;
  },

  saveVisitor: action(function(details) {
    if (this.areVisitorDetailsValid(details)) {
      var id = details.id;
      var data = {
        club: details.club,
        first_name: details.first_name,
        last_name: details.last_name,
        phone: details.phone,
        email: details.email
      };
      if (id) {
        data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      } else {
        data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      }
      if (id) {
        this.visitorsDB
          .doc(id)
          .update(data)
          .then(() => {
            console.log('Visitor updated with ID: ', id);
          })
          .catch(function(error) {
            console.error('Error updating visitor: ', error);
          });
      } else {
        this.visitorsDB
          .add(data)
          .then(function(docRef) {
            console.log('Visitor created with ID: ', docRef.id);
          })
          .catch(function(error) {
            console.error('Error creating visitor: ', error);
          });
      }
    }
  }),

  checkin: action(function(id, clubID, timeIn, type) {
    if (!['athlete', 'visitor'].includes(type)) return;
    var details = {
      club: clubID,
      time_in: firebase.firestore.Timestamp.fromDate(timeIn),
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    details[type] = id;
    this.visitsDB
      .add(details)
      .then(function(docRef) {
        console.log('Visit created with ID: ', docRef.id);
      })
      .catch(function(error) {
        console.error('Error creating visit: ', error);
      });
  }),

  checkinAthlete: action(function(athleteID, clubID, timeIn) {
    this.checkin(athleteID, clubID, timeIn, 'athlete');
  }),

  checkinVisitor: action(function(visitorID, clubID, timeIn) {
    this.checkin(visitorID, clubID, timeIn, 'visitor');
  }),

  checkout: action(function(visitID, timeOut) {
    var visitRef = this.visitsDB.doc(visitID);
    var update = {
      time_out: firebase.firestore.Timestamp.fromDate(timeOut),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    visitRef.update(update).catch(function(error) {
      //XXX error handling
      console.error('Error checking out: ', error);
    });
  }),

  deleteCheckin: action(function(visitID) {
    if (visitID) {
      this.visitsDB
        .doc(visitID)
        .delete()
        .then(function() {
          console.log('Visit successfully deleted!');
        })
        .catch(function(error) {
          //XXX
          console.error('Error removing visit: ', error);
        });
    }
  })
});

export default checkinsStore;
