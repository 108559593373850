import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Menu, Dropdown, Icon, Image, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class MainMenu extends Component {
  toggleFilter = e => {
    const { filters } = this.props.stores;
    filters.ui.open = !filters.ui.open;
  };

  render() {
    const { location } = this.props;
    const { auth, clubs, modules, athletes, filters } = this.props.stores;
    const athleteCount = athletes.allAthletes.length;

    if (!auth.isSignedIn || (!clubs.isCoach && !clubs.isAdmin))
      return (
        <Menu borderless fixed="top">
          <Menu.Item header>
            <Image src="/images/logo.png" className="logo" />
            Trackletic
          </Menu.Item>
        </Menu>
      );

    const resultsCapable =
      (clubs.isCoach || clubs.isAdmin) &&
      modules.numberOfEnabledResultsModules > 0;

    return (
      <Menu borderless fixed="top">
        <Container text>
          <Dropdown item text="MENU" icon={null} className="main-menu">
            <Dropdown.Menu>
              {(clubs.isCoach || clubs.isAdmin) && (
                <Menu.Item
                  active={location.pathname.startsWith('/athletes')}
                  as={Link}
                  to="/athletes"
                  disabled={athleteCount === 0}
                >
                  <Icon name="id card" />
                  Athletes
                </Menu.Item>
              )}
              {resultsCapable && (
                <Menu.Item
                  active={location.pathname.startsWith('/results')}
                  as={Link}
                  to="/results"
                  disabled={athleteCount === 0}
                >
                  <Icon name="users" />
                  Squad Results
                </Menu.Item>
              )}
              {resultsCapable && <Dropdown.Divider />}

              {resultsCapable && (
                <Dropdown.Item
                  as={Link}
                  to="/data"
                  active={
                    location.pathname.startsWith('/data') &&
                    !location.pathname.startsWith('/data/checkins')
                  }
                  disabled={athleteCount === 0}
                >
                  <Icon name="add circle" /> Add Results
                </Dropdown.Item>
              )}
              {resultsCapable && <Dropdown.Divider />}

              {(clubs.isCoach || clubs.isAdmin) &&
                modules.isModuleEnabled('checkins') && (
                  <Dropdown.Item
                    as={Link}
                    to="/data/checkins"
                    active={location.pathname.startsWith('/data/checkins')}
                  >
                    <Icon name="id badge outline" /> Check ins
                  </Dropdown.Item>
                )}
              {(clubs.isCoach || clubs.isAdmin) &&
                modules.isModuleEnabled('checkins') && <Dropdown.Divider />}
              <Dropdown.Item
                as={Link}
                to="/settings"
                active={location.pathname.startsWith('/settings')}
              >
                <Icon name="settings" /> Settings
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={auth.signOut}>
                <Icon name="sign out" /> Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Menu.Item
            header
            style={{
              position: 'fixed',
              marginRight: 'auto',
              marginLeft: 'auto',
              left: 0,
              right: 0,
              width: 'max-content',
              padding: '0.5em'
            }}
          >
            <Image src="/images/logo.png" className="logo" />
            Trackletic
          </Menu.Item>

          <Menu.Menu position="right">
            {(clubs.isCoach || clubs.isAdmin) && (
              <Menu.Item
                onClick={this.toggleFilter}
                style={{
                  backgroundColor: filters.active ? '#ffedcc' : null,
                  fontWeight: filters.ui.open ? 700 : null
                }}
                className="main-menu"
              >
                FILTER
                {filters.active && <br />}
                {filters.active && (
                  <span
                    style={{
                      position: 'absolute',
                      bottom: '0.4em',
                      fontSize: '0.85em',
                      left: '0',
                      right: '0',
                      textAlign: 'center',
                      fontWeight: 500
                    }}
                  >
                    on
                  </span>
                )}
              </Menu.Item>
            )}
          </Menu.Menu>
        </Container>
      </Menu>
    );
  }
}

export default inject('stores')(observer(MainMenu));
