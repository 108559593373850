import { observable, action } from 'mobx';
import firebase from 'firebase';

// User app settings (excluding module specific settings)
var settingsStore = observable({
  settingsRef: null,
  closeDBSession: null,

  loading: true,

  modules: null,

  subscribe(user) {
    if (this.closeDBSession) this.unsubscribe();
    this.settingsRef = firebase
      .firestore()
      .collection('settings')
      .doc(user.uid);
    this.closeDBSession = this.settingsRef.onSnapshot(
      function(doc) {
        const data = doc.data();
        //XXX need to handle case where user doesn't yet exist in the settings db ==> ie doc.data() returns undefinec
        this.modules = data.modules;
        this.loading = false;
      }.bind(this),
      function(error) {
        console.error('Error accessing settings: ' + error.message);
      }
    );
  },

  unsubscribe() {
    if (this.closeDBSession) {
      this.closeDBSession();
    }
    this.settingsRef = null;
    this.closeDBSession = null;
    this.modules = null;
    this.loading = true;
  },

  get numberOfModules() {
    let count = this.modules.length;
    if (
      this.modules.includes('calculated-splits') &&
      !this.modules.includes('time-trial')
    ) {
      count--;
    }
    return count;
  },

  toggleModuleEnabled: action(function(id) {
    if (this.settingsRef && this.modules) {
      const enabled = this.modules.includes(id);
      let details = {
        modules: enabled
          ? firebase.firestore.FieldValue.arrayRemove(id)
          : firebase.firestore.FieldValue.arrayUnion(id),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      this.settingsRef
        .update(details)
        .then(() => {
          // console.log(
          //   'Module "' +
          //     id +
          //     '" ' +
          //     (enabled ? 'enabled' : 'disabled')
          // );
        })
        .catch(error => {
          //XXX error handling
          console.error(
            'Error ' +
              (enabled ? 'disabling' : 'enabling') +
              ' module ' +
              id +
              ': ',
            error
          );
        });
    }
  })
});

export default settingsStore;
