import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';

export default class AthleteAvatar extends Component {
  render() {
    const { athlete, ...imgProps } = this.props;

    let initials = '';
    if (athlete.first_name && athlete.first_name.length > 0) {
      initials += athlete.first_name.charAt(0);
    }
    if (athlete.last_name && athlete.last_name.length > 0) {
      initials += athlete.last_name.charAt(0);
    }

    const color = this.props.color ? this.props.color : 'fff';
    const background = this.props.background ? this.props.background : '0D8ABC';

    const url = athlete.photo_url
      ? athlete.photo_url
      : 'https://ui-avatars.com/api/?name=' +
        initials +
        '&size=128&rounded=true&bold=true&color=' +
        color +
        '&background=' +
        background;

    return <Image src={url} avatar {...imgProps} />;
  }
}
